export const VALID_GUESSES = [
'ababa',
'abaca',
'abaco',
'abada',
'abadi',
'abaja',
'abaje',
'abajo',
'abala',
'abale',
'abalo',
'abana',
'abane',
'abano',
'abasi',
'abata',
'abate',
'abati',
'abato',
'abece',
'abeja',
'abete',
'abeto',
'abiar',
'abias',
'abina',
'abine',
'abino',
'abiso',
'abita',
'abite',
'abito',
'aboba',
'abobe',
'abobo',
'aboca',
'aboco',
'abofa',
'abofe',
'abofo',
'aboga',
'abogo',
'aboli',
'abona',
'abone',
'abono',
'aboya',
'aboye',
'aboyo',
'abozo',
'abran',
'abras',
'abren',
'abres',
'abria',
'abrid',
'abril',
'abrio',
'abrir',
'abris',
'absit',
'abubo',
'abuce',
'abuje',
'abura',
'abure',
'aburo',
'abusa',
'abuse',
'abuso',
'abuza',
'abuzo',
'acaba',
'acabe',
'acabo',
'acama',
'acame',
'acamo',
'acana',
'acara',
'acare',
'acaro',
'acaso',
'acata',
'acate',
'acato',
'acebo',
'acece',
'aceda',
'acede',
'acedo',
'acema',
'acepa',
'acepe',
'acepo',
'acera',
'acere',
'acero',
'aceta',
'aceto',
'aceza',
'acezo',
'achin',
'achis',
'acial',
'aciar',
'acida',
'acido',
'acije',
'acilo',
'acimo',
'acion',
'acles',
'aclla',
'acmes',
'acnes',
'acoca',
'acoco',
'acoda',
'acode',
'acodo',
'acoge',
'acogi',
'acoja',
'acojo',
'acola',
'acole',
'acolo',
'acopa',
'acope',
'acopo',
'acora',
'acore',
'acoro',
'acosa',
'acose',
'acoso',
'acota',
'acote',
'acoto',
'acres',
'acroe',
'acroy',
'actas',
'actea',
'actor',
'actos',
'actua',
'actue',
'actuo',
'acuda',
'acude',
'acudi',
'acudo',
'acuea',
'acueo',
'acula',
'acule',
'aculo',
'acuna',
'acune',
'acuno',
'acure',
'acusa',
'acuse',
'acuso',
'acuta',
'acuti',
'acuto',
'acuyo',
'adala',
'adama',
'adame',
'adamo',
'adaza',
'adema',
'ademe',
'ademo',
'adiad',
'adian',
'adiar',
'adias',
'adien',
'adies',
'adios',
'adiva',
'adive',
'adoba',
'adobe',
'adobo',
'adora',
'adore',
'adoro',
'adosa',
'adose',
'adoso',
'adrad',
'adral',
'adran',
'adrar',
'adras',
'adren',
'adres',
'aduar',
'aduce',
'aduci',
'adufe',
'aduja',
'aduje',
'adujo',
'adula',
'adule',
'adulo',
'aduna',
'adune',
'aduno',
'adura',
'adure',
'aduri',
'aduro',
'adven',
'aedas',
'aedos',
'aerea',
'aereo',
'aetas',
'afaca',
'aface',
'afaga',
'afago',
'afama',
'afame',
'afamo',
'afana',
'afane',
'afano',
'afara',
'afare',
'afata',
'afate',
'afato',
'afead',
'afean',
'afear',
'afeas',
'afeen',
'afees',
'afiar',
'afice',
'afija',
'afijo',
'afila',
'afile',
'afilo',
'afina',
'afine',
'afino',
'afizo',
'aflui',
'afoca',
'afoco',
'afofa',
'afofe',
'afofo',
'afoga',
'afogo',
'afona',
'afono',
'afora',
'afore',
'aforo',
'afosa',
'afose',
'afoso',
'aftas',
'afufa',
'afufe',
'afufo',
'afuma',
'afume',
'afumo',
'agace',
'agami',
'agana',
'agane',
'agano',
'agape',
'agata',
'agave',
'agios',
'agita',
'agite',
'agito',
'agnus',
'agola',
'agole',
'agolo',
'agora',
'agore',
'agoro',
'agota',
'agote',
'agoto',
'agras',
'agraz',
'agres',
'agria',
'agrie',
'agrio',
'agror',
'agros',
'aguad',
'aguai',
'aguan',
'aguar',
'aguas',
'aguay',
'aguce',
'aguda',
'agudo',
'aguen',
'agues',
'aguin',
'aguio',
'aguja',
'aguti',
'aguza',
'aguzo',
'ahaja',
'ahaje',
'ahajo',
'ahija',
'ahije',
'ahijo',
'ahila',
'ahile',
'ahilo',
'ahina',
'ahita',
'ahite',
'ahito',
'ahoga',
'ahogo',
'ahora',
'ahoya',
'ahoye',
'ahoyo',
'ahuma',
'ahume',
'ahumo',
'ahusa',
'ahuse',
'ahuso',
'aillo',
'aillu',
'ainas',
'airad',
'airan',
'airar',
'airas',
'airea',
'airee',
'airen',
'aireo',
'aires',
'airon',
'aisas',
'aisla',
'aisle',
'aislo',
'aites',
'ajaba',
'ajada',
'ajado',
'ajais',
'ajaja',
'ajara',
'ajare',
'ajase',
'ajead',
'ajean',
'ajear',
'ajeas',
'ajebe',
'ajeen',
'ajees',
'ajeis',
'ajena',
'ajeno',
'ajeos',
'ajera',
'ajero',
'ajete',
'ajice',
'ajies',
'ajipa',
'ajiza',
'ajizo',
'ajobo',
'ajora',
'ajore',
'ajoro',
'ajota',
'ajote',
'ajoto',
'ajuar',
'ajuma',
'ajume',
'ajumo',
'ajuna',
'ajuno',
'alaba',
'alabe',
'alabo',
'alaco',
'alada',
'alado',
'alafa',
'alaga',
'alago',
'alaju',
'alala',
'alalo',
'alama',
'alamo',
'alana',
'alano',
'alaui',
'alazo',
'albar',
'albas',
'albea',
'albee',
'albeo',
'albin',
'albor',
'albos',
'album',
'albur',
'alcas',
'alcea',
'alcen',
'alces',
'alcor',
'aldea',
'alead',
'alean',
'alear',
'aleas',
'alece',
'aleda',
'aleen',
'alees',
'alefs',
'alega',
'alego',
'aleja',
'aleje',
'alejo',
'alela',
'alele',
'aleli',
'alelo',
'alema',
'alero',
'aleta',
'aleto',
'aleve',
'aleya',
'alezo',
'alfad',
'alfan',
'alfar',
'alfas',
'alfen',
'alfes',
'alfil',
'alfiz',
'alfoz',
'algar',
'algas',
'algol',
'algos',
'algun',
'alhoz',
'aliad',
'alian',
'aliar',
'alias',
'alica',
'alien',
'alier',
'alies',
'alifa',
'aliga',
'aligo',
'alija',
'alije',
'alijo',
'alimo',
'alims',
'alioj',
'alisa',
'alise',
'aliso',
'aljez',
'aljor',
'allen',
'almas',
'almea',
'almez',
'almos',
'almud',
'alnas',
'alnos',
'aloba',
'alobe',
'alobo',
'aloca',
'aloco',
'aloes',
'aloja',
'aloje',
'alojo',
'alola',
'alole',
'alolo',
'aloma',
'alome',
'alomo',
'alona',
'alora',
'alosa',
'alota',
'alote',
'aloto',
'aloya',
'alpes',
'altar',
'altas',
'altea',
'altee',
'alteo',
'altor',
'altos',
'aluas',
'aluce',
'aluda',
'alude',
'aludi',
'aludo',
'alula',
'aluna',
'alune',
'aluno',
'aluza',
'aluzo',
'alveo',
'alzad',
'alzan',
'alzar',
'alzas',
'alzos',
'amaba',
'amada',
'amado',
'amaga',
'amago',
'amais',
'amala',
'amale',
'amalo',
'amana',
'amane',
'amano',
'amara',
'amare',
'amaro',
'amasa',
'amase',
'amaso',
'amata',
'amate',
'amato',
'ambar',
'ambas',
'ambla',
'amble',
'amblo',
'ambon',
'ambos',
'ameba',
'ameis',
'amela',
'amele',
'amelo',
'amena',
'ameno',
'ameos',
'amera',
'amere',
'amero',
'amias',
'amiba',
'amibo',
'amida',
'amiga',
'amigo',
'amina',
'amine',
'amino',
'amiri',
'amito',
'amola',
'amole',
'amolo',
'amomo',
'amona',
'amone',
'amono',
'amove',
'amovi',
'ampay',
'ampla',
'amplo',
'ampon',
'ampos',
'ampra',
'ampre',
'ampro',
'amuga',
'amugo',
'amula',
'amule',
'amulo',
'amura',
'amure',
'amuro',
'amuso',
'anabi',
'anaco',
'anade',
'anafe',
'anamu',
'anana',
'anata',
'ancas',
'ancha',
'anche',
'ancho',
'ancla',
'ancle',
'anclo',
'ancon',
'ancua',
'andad',
'andan',
'andar',
'andas',
'andel',
'anden',
'andes',
'andon',
'anead',
'anean',
'anear',
'aneas',
'aneen',
'anees',
'anega',
'anego',
'aneja',
'aneje',
'anejo',
'aneto',
'anexa',
'anexe',
'anexo',
'angel',
'angla',
'anglo',
'angor',
'angra',
'angus',
'anida',
'anide',
'anido',
'anima',
'anime',
'animo',
'anion',
'anisa',
'anise',
'aniso',
'anito',
'anjeo',
'anoas',
'anodo',
'anola',
'anole',
'anolo',
'anona',
'anota',
'anote',
'anoto',
'ansar',
'ansas',
'ansia',
'ansie',
'ansio',
'antas',
'antes',
'antia',
'antis',
'antro',
'anual',
'anuas',
'anuda',
'anude',
'anudo',
'anula',
'anule',
'anulo',
'anuos',
'anura',
'anuro',
'aocar',
'aojad',
'aojan',
'aojar',
'aojas',
'aojen',
'aojes',
'aojos',
'aonia',
'aonio',
'aorta',
'aovad',
'aovan',
'aovar',
'aovas',
'aoven',
'aoves',
'apaga',
'apago',
'apale',
'apara',
'apare',
'aparo',
'apead',
'apean',
'apear',
'apeas',
'apeen',
'apees',
'apega',
'apego',
'apela',
'apele',
'apelo',
'apena',
'apene',
'apeno',
'apeos',
'apera',
'apere',
'apero',
'apese',
'apice',
'apila',
'apile',
'apilo',
'apios',
'apipa',
'apipe',
'apipo',
'apiri',
'apita',
'apite',
'apito',
'apnea',
'apoca',
'apoce',
'apoco',
'apoda',
'apode',
'apodo',
'apola',
'apole',
'apolo',
'apone',
'aposa',
'apose',
'aposo',
'apoya',
'apoye',
'apoyo',
'apoza',
'apozo',
'apres',
'aproa',
'aproe',
'aproo',
'aptar',
'aptas',
'aptos',
'apuna',
'apune',
'apuno',
'apura',
'apure',
'apuro',
'apuse',
'apuso',
'aquea',
'aquel',
'aqueo',
'araba',
'arabe',
'arabi',
'arabo',
'arada',
'arado',
'arais',
'arana',
'arara',
'arare',
'arasa',
'arase',
'araza',
'arbol',
'arbor',
'arcad',
'arcan',
'arcar',
'arcas',
'arcea',
'arcen',
'arces',
'archa',
'archi',
'arcon',
'arcos',
'ardan',
'ardas',
'ardea',
'arded',
'arden',
'arder',
'ardes',
'ardia',
'ardid',
'ardil',
'ardio',
'ardor',
'ardua',
'arduo',
'areas',
'areca',
'areis',
'arela',
'arele',
'arelo',
'arena',
'arene',
'areno',
'arepa',
'arete',
'arfad',
'arfan',
'arfar',
'arfas',
'arfen',
'arfes',
'arfil',
'argan',
'argel',
'argen',
'argon',
'argos',
'argot',
'argue',
'argui',
'arias',
'arica',
'arico',
'arida',
'arido',
'aries',
'arija',
'arije',
'arijo',
'arilo',
'arios',
'arlad',
'arlan',
'arlar',
'arlas',
'arlen',
'arles',
'arlos',
'armad',
'arman',
'armar',
'armas',
'armen',
'armes',
'armon',
'armos',
'arnas',
'arnes',
'aroca',
'aroma',
'arome',
'aromo',
'arpad',
'arpan',
'arpar',
'arpas',
'arpen',
'arpeo',
'arpes',
'arpia',
'arpon',
'arque',
'arras',
'arraz',
'arrea',
'arree',
'arreo',
'arres',
'arria',
'arrie',
'arrio',
'arroz',
'arrua',
'arrue',
'arrui',
'arruo',
'artal',
'artas',
'artes',
'artos',
'arula',
'arupo',
'arzon',
'asaba',
'asaca',
'asaco',
'asada',
'asado',
'asais',
'asara',
'asare',
'asaro',
'asase',
'ascar',
'ascas',
'ascia',
'ascio',
'ascos',
'ascua',
'asead',
'asean',
'asear',
'aseas',
'aseda',
'asede',
'asedo',
'aseen',
'asees',
'aseis',
'asela',
'asele',
'aselo',
'aseos',
'asesa',
'asese',
'aseso',
'asgan',
'asgas',
'asian',
'asias',
'asica',
'asico',
'asida',
'asido',
'asila',
'asile',
'asilo',
'asina',
'asira',
'asire',
'asmar',
'asmas',
'asnal',
'asnas',
'asnos',
'asola',
'asole',
'asolo',
'asoma',
'asome',
'asomo',
'asona',
'asone',
'asono',
'aspad',
'aspan',
'aspar',
'aspas',
'aspea',
'aspee',
'aspen',
'aspeo',
'aspes',
'aspic',
'aspid',
'aspro',
'astas',
'aster',
'astil',
'astro',
'astur',
'asuma',
'asume',
'asumi',
'asumo',
'asura',
'asure',
'asuro',
'asuso',
'ataba',
'atabe',
'ataca',
'ataco',
'atada',
'atado',
'atais',
'ataja',
'ataje',
'atajo',
'atapa',
'atape',
'atapo',
'atara',
'atare',
'atase',
'ataud',
'atear',
'ateas',
'atece',
'ateis',
'ateje',
'ateos',
'ateri',
'atesa',
'atese',
'ateso',
'ateta',
'atete',
'ateto',
'ateza',
'atezo',
'atiba',
'atibe',
'atibo',
'atica',
'atice',
'atico',
'atina',
'atine',
'atino',
'atipa',
'atipe',
'atipo',
'atiza',
'atizo',
'atlas',
'atoad',
'atoan',
'atoar',
'atoas',
'atoba',
'atobe',
'atobo',
'atoen',
'atoes',
'atoja',
'atoje',
'atojo',
'atole',
'atomo',
'atona',
'atono',
'atora',
'atore',
'atoro',
'atrae',
'atras',
'atril',
'atrio',
'atroz',
'atufa',
'atufe',
'atufo',
'atura',
'ature',
'aturo',
'atusa',
'atuse',
'atuso',
'atuve',
'atuvo',
'aucas',
'audaz',
'audio',
'auges',
'augur',
'aulas',
'aulla',
'aulle',
'aullo',
'aunad',
'aunan',
'aunar',
'aunas',
'aunen',
'aunes',
'aupad',
'aupan',
'aupar',
'aupas',
'aupen',
'aupes',
'auras',
'aurea',
'aureo',
'ausol',
'autan',
'autor',
'autos',
'avada',
'avade',
'avado',
'avaha',
'avahe',
'avaho',
'avala',
'avale',
'avalo',
'avara',
'avaro',
'avati',
'avece',
'avena',
'avene',
'aveni',
'aveno',
'aveza',
'avezo',
'aviad',
'avian',
'aviar',
'avias',
'avica',
'avida',
'avido',
'avien',
'avies',
'avine',
'avino',
'avion',
'avios',
'avisa',
'avise',
'aviso',
'aviva',
'avive',
'avivo',
'avoca',
'avoco',
'avugo',
'axial',
'axila',
'ayaco',
'ayate',
'ayead',
'ayean',
'ayear',
'ayeas',
'ayeen',
'ayees',
'ayora',
'ayote',
'ayuas',
'ayuda',
'ayude',
'ayudo',
'ayuga',
'ayuna',
'ayune',
'ayuno',
'ayuso',
'azada',
'azaga',
'azago',
'azala',
'azara',
'azare',
'azaro',
'azcon',
'azeri',
'azimo',
'azoad',
'azoan',
'azoar',
'azoas',
'azoca',
'azoco',
'azoen',
'azoes',
'azoga',
'azogo',
'azola',
'azole',
'azolo',
'azora',
'azore',
'azoro',
'azota',
'azote',
'azoto',
'aztor',
'azuas',
'azuce',
'azuda',
'azula',
'azule',
'azulo',
'azuts',
'azuza',
'azuzo',
'babas',
'babea',
'babee',
'babel',
'babeo',
'babis',
'bable',
'babor',
'bacan',
'bacas',
'bache',
'bacia',
'bacin',
'bacon',
'badal',
'badan',
'badas',
'badea',
'baden',
'badil',
'bafle',
'bagad',
'bagan',
'bagar',
'bagas',
'bagos',
'bagre',
'bague',
'bahai',
'bahia',
'baida',
'baifa',
'baifo',
'baila',
'baile',
'bailo',
'bajad',
'bajan',
'bajar',
'bajas',
'bajea',
'bajee',
'bajel',
'bajen',
'bajeo',
'bajes',
'bajez',
'bajia',
'bajin',
'bajio',
'bajon',
'bajos',
'balad',
'balaj',
'balan',
'balar',
'balas',
'balay',
'balda',
'balde',
'baldo',
'balea',
'balee',
'balen',
'baleo',
'bales',
'balin',
'balon',
'balos',
'balsa',
'balso',
'balta',
'balto',
'bamba',
'bambu',
'banal',
'banas',
'banca',
'bance',
'banco',
'banda',
'bando',
'banir',
'banjo',
'bantu',
'banyo',
'banzo',
'baque',
'barba',
'barbe',
'barbo',
'barca',
'barco',
'barda',
'barde',
'bardo',
'bares',
'baria',
'baril',
'bario',
'baris',
'barns',
'baron',
'baros',
'barra',
'barre',
'barri',
'barro',
'barza',
'basad',
'basal',
'basan',
'basar',
'basas',
'basca',
'basen',
'bases',
'basis',
'basna',
'basta',
'baste',
'basto',
'batan',
'batas',
'batea',
'batee',
'batel',
'baten',
'bateo',
'bates',
'batey',
'batia',
'batid',
'batin',
'batio',
'batir',
'batis',
'baton',
'batos',
'batua',
'baula',
'baure',
'bausa',
'bauza',
'bayal',
'bayas',
'bayon',
'bayos',
'bayua',
'bayus',
'bazar',
'bazas',
'bazos',
'beata',
'beato',
'beban',
'bebas',
'bebed',
'beben',
'beber',
'bebes',
'bebia',
'bebio',
'becad',
'becan',
'becar',
'becas',
'bedel',
'befad',
'befan',
'befar',
'befas',
'befen',
'befes',
'befos',
'befre',
'begum',
'beige',
'bejin',
'belda',
'belde',
'beldo',
'belen',
'beles',
'belez',
'belfa',
'belfo',
'belga',
'belio',
'bella',
'bello',
'belua',
'bemba',
'bembe',
'bembo',
'bemol',
'benes',
'beoda',
'beodo',
'beori',
'beque',
'berbi',
'berma',
'beron',
'berra',
'berre',
'berro',
'berta',
'berza',
'besad',
'besan',
'besar',
'besas',
'besen',
'beses',
'besos',
'betas',
'betel',
'betun',
'beuda',
'beudo',
'beyes',
'bezar',
'bezon',
'bezos',
'biaza',
'bibis',
'bical',
'bicha',
'biche',
'bicho',
'bicis',
'bicos',
'bides',
'bidon',
'biela',
'bifaz',
'bifes',
'bigas',
'bijao',
'bijas',
'bijol',
'bilao',
'biles',
'bilis',
'billa',
'bilma',
'bilme',
'bilmo',
'bimba',
'binad',
'binan',
'binar',
'binas',
'binea',
'binee',
'binen',
'bineo',
'bines',
'bingo',
'binza',
'biota',
'biras',
'birla',
'birle',
'birli',
'birlo',
'biros',
'bisad',
'bisan',
'bisar',
'bisas',
'bisel',
'bisen',
'bises',
'bisos',
'biste',
'bitad',
'bitan',
'bitar',
'bitas',
'biten',
'biter',
'bites',
'bitor',
'bizas',
'bizca',
'bizco',
'bizma',
'bizme',
'bizmo',
'bizna',
'blaos',
'blava',
'blavo',
'bleda',
'bledo',
'bloca',
'bloco',
'blocs',
'blues',
'blusa',
'boato',
'bobas',
'bobea',
'bobee',
'bobeo',
'bobos',
'bocal',
'bocas',
'bocea',
'bocee',
'bocel',
'boceo',
'bocha',
'boche',
'bocho',
'bocin',
'bocio',
'bocon',
'bocoy',
'bodas',
'bodes',
'bodon',
'bofan',
'bofar',
'bofas',
'bofen',
'bofes',
'bofia',
'bofos',
'bogad',
'bogan',
'bogar',
'bogas',
'bogue',
'bohio',
'boina',
'boira',
'boite',
'bojad',
'bojan',
'bojar',
'bojas',
'bojea',
'bojee',
'bojen',
'bojeo',
'bojes',
'bojos',
'bolar',
'bolas',
'boldo',
'bolea',
'bolee',
'boleo',
'boles',
'bolin',
'bolis',
'bolla',
'bolle',
'bollo',
'bolon',
'bolos',
'bolsa',
'bolso',
'bomba',
'bombe',
'bombo',
'bonal',
'bones',
'bonga',
'bongo',
'bonos',
'bonzo',
'boque',
'boqui',
'borax',
'borda',
'borde',
'bordo',
'boreo',
'boria',
'borla',
'borna',
'borne',
'borni',
'boros',
'borra',
'borre',
'borro',
'borto',
'bosar',
'boson',
'bosta',
'botad',
'botan',
'botar',
'botas',
'botea',
'botee',
'boten',
'boteo',
'botes',
'botin',
'boton',
'botor',
'botos',
'boxea',
'boxee',
'boxeo',
'boxer',
'boxes',
'boyad',
'boyal',
'boyan',
'boyar',
'boyas',
'boyen',
'boyes',
'bozal',
'bozas',
'bozon',
'bozos',
'braca',
'braco',
'braga',
'brama',
'brame',
'bramo',
'brasa',
'brava',
'bravo',
'braza',
'brazo',
'bread',
'brean',
'brear',
'breas',
'breca',
'brece',
'breco',
'breen',
'brees',
'brega',
'brego',
'brete',
'breva',
'breve',
'breza',
'brezo',
'brial',
'briba',
'brice',
'brida',
'briol',
'brios',
'brisa',
'brise',
'briso',
'briza',
'brizo',
'broas',
'broca',
'broce',
'broma',
'brome',
'bromo',
'brota',
'brote',
'broto',
'broza',
'brozo',
'bruce',
'brugo',
'bruja',
'bruje',
'bruji',
'brujo',
'bruma',
'brume',
'brumo',
'bruna',
'bruno',
'bruta',
'bruto',
'bruts',
'bruza',
'bruzo',
'buaro',
'bubas',
'bubis',
'bubon',
'bucal',
'bucea',
'bucee',
'bucen',
'buceo',
'buces',
'buche',
'bucio',
'bucle',
'bucos',
'budas',
'budin',
'buega',
'buena',
'bueno',
'buera',
'bufad',
'bufan',
'bufar',
'bufas',
'bufen',
'bufeo',
'bufes',
'bufet',
'bufia',
'bufon',
'bufos',
'bugle',
'buhio',
'buhos',
'buida',
'buido',
'bujeo',
'bujes',
'bujia',
'bujos',
'bular',
'bulas',
'bulbo',
'bulda',
'bules',
'bulin',
'bulis',
'bulla',
'bulle',
'bulli',
'bullo',
'bulon',
'bulos',
'bulto',
'bunas',
'bunio',
'buque',
'buras',
'burda',
'burdo',
'burel',
'bureo',
'burga',
'burgo',
'buril',
'burio',
'buris',
'burla',
'burle',
'burlo',
'buros',
'burra',
'burro',
'busca',
'busco',
'buses',
'busto',
'buten',
'butia',
'buyos',
'buzad',
'buzan',
'buzar',
'buzas',
'buzon',
'buzos',
'cabal',
'cabas',
'cabed',
'caben',
'caber',
'cabes',
'cabia',
'cabio',
'cable',
'cabos',
'cabra',
'cabre',
'cabro',
'cacan',
'cacao',
'cacas',
'cacea',
'cacee',
'cacen',
'caceo',
'caces',
'cacha',
'cache',
'cacho',
'cachu',
'cacle',
'cacos',
'cacto',
'cacuy',
'cadas',
'cadis',
'cados',
'caeis',
'caena',
'caera',
'caere',
'cafes',
'cafiz',
'cafre',
'cagad',
'cagan',
'cagar',
'cagas',
'cagon',
'cague',
'cahiz',
'caian',
'caias',
'caico',
'caida',
'caido',
'caies',
'caiga',
'caigo',
'caima',
'caire',
'caite',
'cajas',
'cajel',
'cajin',
'cajis',
'cajon',
'cajos',
'calad',
'calan',
'calao',
'calar',
'calas',
'calca',
'calce',
'calco',
'calda',
'caldo',
'caled',
'calen',
'caler',
'cales',
'calia',
'calio',
'calis',
'caliz',
'calla',
'calle',
'callo',
'calma',
'calme',
'calmo',
'calon',
'calor',
'calos',
'calta',
'calva',
'calve',
'calvo',
'calza',
'calzo',
'camal',
'camao',
'camas',
'camba',
'cambe',
'cambo',
'camio',
'camon',
'campa',
'campe',
'campo',
'canal',
'canas',
'canda',
'cande',
'candi',
'cando',
'canea',
'canee',
'caneo',
'canes',
'caney',
'canez',
'canga',
'cango',
'cania',
'canil',
'canje',
'canoa',
'canon',
'canos',
'cansa',
'canse',
'canso',
'canta',
'cante',
'canto',
'cantu',
'caoba',
'caobo',
'capad',
'capan',
'capar',
'capas',
'capaz',
'capea',
'capee',
'capel',
'capen',
'capeo',
'capes',
'capia',
'capin',
'capio',
'capis',
'capon',
'capos',
'cappa',
'capta',
'capte',
'capto',
'capuz',
'caqui',
'carao',
'caras',
'carau',
'caray',
'carba',
'carca',
'carda',
'carde',
'cardo',
'carea',
'caree',
'carel',
'careo',
'cares',
'carey',
'carga',
'cargo',
'caria',
'carie',
'cario',
'caris',
'cariz',
'carla',
'carlo',
'carme',
'carne',
'caron',
'caros',
'carpa',
'carpe',
'carpi',
'carpo',
'carra',
'carro',
'carta',
'carvi',
'casad',
'casal',
'casan',
'casar',
'casas',
'casca',
'casco',
'casea',
'casen',
'caseo',
'cases',
'casia',
'casis',
'cason',
'casos',
'caspa',
'casta',
'casto',
'catad',
'catan',
'catar',
'catas',
'catea',
'catee',
'caten',
'cateo',
'cates',
'catey',
'catin',
'caton',
'catos',
'catre',
'cauba',
'cauca',
'cauce',
'cauda',
'cauje',
'caula',
'cauno',
'cauri',
'cauro',
'causa',
'cause',
'causo',
'cauta',
'cauto',
'cauza',
'cavad',
'cavan',
'cavar',
'cavas',
'cavea',
'caven',
'caves',
'cavia',
'cavio',
'cavis',
'cavon',
'cavos',
'cayan',
'cayas',
'cayos',
'cazad',
'cazan',
'cazar',
'cazas',
'cazon',
'cazos',
'cazuz',
'ceaja',
'ceajo',
'cebad',
'ceban',
'cebar',
'cebas',
'ceben',
'cebes',
'cebil',
'cebon',
'cebos',
'cebra',
'cebro',
'cebti',
'cebus',
'cecal',
'cecas',
'cecea',
'cecee',
'ceceo',
'cedan',
'cedas',
'ceded',
'ceden',
'ceder',
'cedes',
'cedia',
'cedio',
'cedro',
'cefea',
'cefee',
'cefeo',
'cefos',
'cegad',
'cegar',
'cegas',
'cegua',
'cegue',
'ceiba',
'ceibo',
'cejad',
'cejan',
'cejar',
'cejas',
'cejen',
'cejes',
'cejos',
'celad',
'celan',
'celar',
'celas',
'celda',
'celen',
'celes',
'celfo',
'celia',
'cella',
'cello',
'celos',
'celta',
'cemas',
'cembo',
'cenad',
'cenal',
'cenan',
'cenar',
'cenas',
'cenca',
'cenco',
'cenen',
'cenes',
'cenia',
'cenis',
'cenit',
'censa',
'cense',
'censo',
'cents',
'cepas',
'cepos',
'cepti',
'cequi',
'ceras',
'cerca',
'cerco',
'cerda',
'cerdo',
'cerea',
'cereo',
'cerio',
'cerna',
'cerne',
'cerni',
'cerno',
'ceron',
'ceros',
'cerpa',
'cerra',
'cerre',
'cerro',
'cesad',
'cesan',
'cesar',
'cesas',
'cesen',
'ceses',
'cesio',
'cesta',
'cesto',
'cetil',
'cetis',
'cetme',
'cetra',
'cetre',
'cetro',
'ceuti',
'chaca',
'chace',
'chaco',
'chafa',
'chafe',
'chafo',
'chais',
'chaja',
'chala',
'chale',
'chalo',
'chama',
'chame',
'chamo',
'chana',
'chane',
'chano',
'chapa',
'chape',
'chapo',
'chata',
'chato',
'chaul',
'chauz',
'chava',
'chave',
'chavo',
'chaya',
'chaye',
'chayo',
'chaza',
'chazo',
'checa',
'checo',
'chefs',
'cheje',
'chela',
'chele',
'cheli',
'chelo',
'chepa',
'chepe',
'chepo',
'chera',
'chero',
'chesa',
'cheso',
'cheta',
'cheto',
'chiad',
'chian',
'chiar',
'chias',
'chica',
'chico',
'chics',
'chida',
'chido',
'chien',
'chies',
'chifa',
'chiis',
'chile',
'chima',
'chime',
'chimo',
'chimu',
'china',
'chine',
'chino',
'chipa',
'chipe',
'chips',
'chira',
'chiri',
'chisa',
'chist',
'chita',
'chite',
'chito',
'chiva',
'chive',
'chivo',
'chiza',
'choba',
'choca',
'choco',
'chofe',
'chola',
'cholo',
'chona',
'chono',
'chopa',
'chope',
'chopo',
'chora',
'chore',
'chori',
'choro',
'chota',
'chote',
'choto',
'chova',
'choya',
'choye',
'choyo',
'choza',
'chozo',
'chuas',
'chuca',
'chuce',
'chuco',
'chufa',
'chufe',
'chufo',
'chula',
'chule',
'chulo',
'chuna',
'chupa',
'chupe',
'chupo',
'chura',
'churo',
'churu',
'chuta',
'chute',
'chuto',
'chuts',
'chuva',
'chuya',
'chuyo',
'chuza',
'chuzo',
'ciaba',
'ciado',
'ciais',
'ciani',
'ciara',
'ciare',
'ciase',
'ciato',
'cibal',
'cibis',
'cicas',
'cicca',
'cicla',
'cicle',
'ciclo',
'cides',
'cidra',
'cidro',
'cieca',
'ciega',
'ciego',
'cieis',
'cielo',
'ciemo',
'cieno',
'cifra',
'cifre',
'cifro',
'cigua',
'cijas',
'cilio',
'cilla',
'cimar',
'cimas',
'cimba',
'cimia',
'cimpa',
'cinas',
'cinca',
'cinco',
'cincs',
'cines',
'cinia',
'cinta',
'cinte',
'cinto',
'cipes',
'cipos',
'circa',
'circe',
'circo',
'cirio',
'cirro',
'cisca',
'cisco',
'cisma',
'cisme',
'cismo',
'cisne',
'cista',
'citad',
'citan',
'citar',
'citas',
'citen',
'cites',
'citra',
'civil',
'claco',
'clacs',
'clama',
'clame',
'clamo',
'clapa',
'clara',
'claro',
'clase',
'clava',
'clave',
'clavo',
'clema',
'clero',
'clica',
'clics',
'clima',
'clips',
'clisa',
'clise',
'cliso',
'cloca',
'cloco',
'clona',
'clone',
'clono',
'clora',
'clore',
'cloro',
'clota',
'clube',
'clubs',
'coana',
'coati',
'cobas',
'cobea',
'cobez',
'cobil',
'cobla',
'cobos',
'cobra',
'cobre',
'cobro',
'cocad',
'cocal',
'cocan',
'cocar',
'cocas',
'cocea',
'coced',
'cocee',
'coceo',
'cocer',
'coces',
'cocha',
'coche',
'cochi',
'cocho',
'cocia',
'cocio',
'cocol',
'cocos',
'cocui',
'cocuy',
'codal',
'codas',
'codea',
'codee',
'codeo',
'codez',
'codin',
'codon',
'codos',
'coeva',
'coevo',
'cofan',
'cofas',
'cofia',
'cofin',
'cofre',
'coged',
'cogen',
'coger',
'coges',
'cogia',
'cogio',
'cogon',
'coima',
'coime',
'coine',
'coipo',
'coita',
'coite',
'coito',
'cojal',
'cojan',
'cojas',
'cojea',
'cojee',
'cojeo',
'cojin',
'cojon',
'cojos',
'colad',
'colan',
'colar',
'colas',
'colea',
'colee',
'colen',
'coleo',
'coles',
'colga',
'colgo',
'colin',
'colla',
'colma',
'colme',
'colmo',
'colon',
'color',
'colos',
'colpa',
'colpe',
'colza',
'comal',
'coman',
'comas',
'comba',
'combe',
'combo',
'comed',
'comen',
'comer',
'comes',
'comia',
'comic',
'comio',
'comis',
'comta',
'comto',
'comun',
'conca',
'conde',
'conga',
'congo',
'conos',
'conta',
'conte',
'conto',
'coona',
'copad',
'copal',
'copan',
'copar',
'copas',
'copea',
'copec',
'copee',
'copen',
'copeo',
'copes',
'copey',
'copia',
'copie',
'copin',
'copio',
'copla',
'copon',
'copos',
'copra',
'copta',
'copto',
'coque',
'coqui',
'corad',
'coral',
'coran',
'corar',
'coras',
'corbe',
'corca',
'corco',
'corda',
'corea',
'coree',
'coren',
'coreo',
'cores',
'corio',
'coris',
'corla',
'corle',
'corlo',
'corma',
'corno',
'coros',
'corpa',
'corps',
'corra',
'corre',
'corri',
'corro',
'corsa',
'corse',
'corso',
'corta',
'corte',
'corto',
'corua',
'corva',
'corve',
'corvo',
'corza',
'corzo',
'cosan',
'cosas',
'cosca',
'cosco',
'cosed',
'cosen',
'coser',
'coses',
'cosia',
'cosio',
'cosos',
'cospe',
'costa',
'coste',
'costo',
'cotad',
'cotan',
'cotar',
'cotas',
'coten',
'cotes',
'cotin',
'cotis',
'coton',
'cotos',
'cotua',
'covad',
'covan',
'covar',
'covas',
'coven',
'coves',
'coxal',
'coxas',
'coxis',
'coyan',
'coyas',
'coyes',
'coyol',
'cracs',
'crasa',
'craso',
'craza',
'cread',
'crean',
'crear',
'creas',
'crece',
'creci',
'credo',
'creed',
'creen',
'creer',
'crees',
'creia',
'crema',
'creme',
'cremo',
'crepe',
'creps',
'cresa',
'creso',
'creta',
'creyo',
'criad',
'crian',
'criar',
'crias',
'criba',
'cribe',
'cribo',
'crica',
'crics',
'crida',
'crien',
'cries',
'crina',
'crine',
'crino',
'crios',
'croad',
'croan',
'croar',
'croas',
'croco',
'croen',
'croes',
'croma',
'crome',
'cromo',
'crono',
'cross',
'croto',
'croza',
'cruce',
'cruda',
'crudo',
'cruel',
'cruja',
'cruje',
'cruji',
'crujo',
'cruor',
'crups',
'cruza',
'cruzo',
'cuaba',
'cuaco',
'cuada',
'cuado',
'cuaja',
'cuaje',
'cuajo',
'cuape',
'cuasi',
'cuata',
'cuate',
'cuati',
'cubas',
'cubil',
'cubos',
'cubra',
'cubre',
'cubri',
'cubro',
'cucad',
'cucan',
'cucar',
'cucas',
'cucha',
'cuche',
'cuchi',
'cucho',
'cucos',
'cucus',
'cucuy',
'cueca',
'cuece',
'cueco',
'cuela',
'cuele',
'cuelo',
'cuera',
'cuero',
'cuete',
'cueto',
'cueva',
'cueza',
'cuezo',
'cuica',
'cuico',
'cuida',
'cuide',
'cuido',
'cuija',
'cuilo',
'cuina',
'cuino',
'cuita',
'cujas',
'cujes',
'cujin',
'cujis',
'cujon',
'cular',
'culas',
'culea',
'culee',
'culen',
'culeo',
'culia',
'culio',
'culis',
'culle',
'culon',
'culos',
'culpa',
'culpe',
'culpo',
'culta',
'culto',
'cumas',
'cumba',
'cumbe',
'cumbo',
'cumel',
'cumpa',
'cunad',
'cunan',
'cunar',
'cunas',
'cunda',
'cunde',
'cundi',
'cundo',
'cunea',
'cunee',
'cunen',
'cuneo',
'cunes',
'cuota',
'cupes',
'cuple',
'cupon',
'cupos',
'cuque',
'curad',
'cural',
'curan',
'curar',
'curas',
'curca',
'curco',
'curda',
'curdo',
'curen',
'cures',
'curia',
'curie',
'curil',
'curio',
'curis',
'curos',
'curra',
'curre',
'curro',
'curry',
'cursa',
'curse',
'cursi',
'curso',
'curta',
'curte',
'curti',
'curto',
'curul',
'curva',
'curve',
'curvo',
'cusan',
'cusas',
'cusca',
'cusco',
'cuscu',
'cusen',
'cuses',
'cusia',
'cusid',
'cusio',
'cusir',
'cusis',
'cusma',
'cuspa',
'cusul',
'cutas',
'cuter',
'cutes',
'cutio',
'cutir',
'cutis',
'cutos',
'cutra',
'cutre',
'cuyas',
'cuyeo',
'cuyes',
'cuyos',
'cuzas',
'cuzco',
'cuzma',
'cuzos',
'cuzul',
'daban',
'dabas',
'dable',
'dacha',
'dacia',
'dacio',
'dadas',
'dador',
'dados',
'dagas',
'dahir',
'daifa',
'dajao',
'dalas',
'dalgo',
'dalia',
'dalla',
'dalle',
'dallo',
'damas',
'damil',
'damos',
'dance',
'dandi',
'dando',
'danes',
'dango',
'danta',
'dante',
'danto',
'danza',
'danzo',
'daqui',
'daran',
'daras',
'dardo',
'dares',
'darga',
'daria',
'datad',
'datan',
'datar',
'datas',
'datea',
'datee',
'daten',
'dateo',
'dates',
'datil',
'datos',
'dauco',
'dauda',
'dazas',
'deban',
'debas',
'debda',
'debdo',
'debed',
'deben',
'deber',
'debes',
'debia',
'debil',
'debio',
'debla',
'debos',
'debut',
'decae',
'decai',
'decia',
'decid',
'decir',
'decis',
'decor',
'dedal',
'dedeo',
'dedil',
'dedos',
'deesa',
'dejad',
'dejan',
'dejar',
'dejas',
'dejen',
'dejes',
'dejos',
'delco',
'deles',
'delga',
'delia',
'delio',
'della',
'dello',
'delta',
'demas',
'demos',
'dende',
'densa',
'denso',
'denta',
'dente',
'dento',
'depon',
'deque',
'derbi',
'desca',
'desda',
'desde',
'desdi',
'desea',
'desee',
'deseo',
'deses',
'desga',
'desoi',
'desta',
'deste',
'desto',
'desus',
'detal',
'deten',
'deuda',
'deudo',
'deven',
'deyes',
'dezma',
'dezme',
'dezmo',
'diada',
'diado',
'diana',
'dicaz',
'dicen',
'dices',
'dicha',
'dicho',
'dicta',
'dicte',
'dicto',
'diego',
'diera',
'diere',
'diese',
'diesi',
'dieta',
'diete',
'dieto',
'digan',
'digas',
'digna',
'digne',
'digno',
'dijes',
'dilui',
'diman',
'dimas',
'dimen',
'dimes',
'dimia',
'dimid',
'dimio',
'dimir',
'dimis',
'dimos',
'dinar',
'dinas',
'dines',
'dinos',
'diodo',
'diosa',
'dioso',
'dique',
'diran',
'diras',
'diria',
'disca',
'disco',
'dison',
'dista',
'diste',
'disto',
'ditas',
'diuca',
'divan',
'divas',
'divos',
'dobla',
'doble',
'doblo',
'docas',
'doces',
'docil',
'docta',
'docto',
'dodos',
'dogal',
'dogas',
'dogma',
'dogos',
'dogre',
'dolad',
'dolar',
'dolas',
'doled',
'doler',
'doles',
'dolia',
'dolio',
'dolor',
'dolos',
'domad',
'doman',
'domar',
'domas',
'dombo',
'domen',
'domes',
'domos',
'donad',
'donan',
'donar',
'donas',
'donde',
'donen',
'doneo',
'dones',
'dopad',
'dopan',
'dopar',
'dopas',
'dopen',
'dopes',
'dorad',
'doral',
'doran',
'dorar',
'doras',
'doren',
'dores',
'doria',
'dorio',
'dormi',
'dorna',
'dorso',
'dosel',
'doses',
'dosis',
'dotad',
'dotal',
'dotan',
'dotar',
'dotas',
'doten',
'dotes',
'dotor',
'draba',
'draga',
'drago',
'drama',
'dreas',
'drena',
'drene',
'dreno',
'drias',
'drino',
'driza',
'droga',
'drogo',
'drope',
'drupa',
'drusa',
'druso',
'dseda',
'dubas',
'dubda',
'dubio',
'ducal',
'ducas',
'duces',
'ducha',
'duche',
'ducho',
'ducos',
'ducto',
'dudad',
'dudan',
'dudar',
'dudas',
'duden',
'dudes',
'duela',
'duele',
'duelo',
'dueto',
'dugos',
'dujos',
'dular',
'dulas',
'dulce',
'dulia',
'duman',
'dumas',
'dumen',
'dumes',
'dumia',
'dumid',
'dumio',
'dumir',
'dumis',
'dunas',
'dunda',
'dundo',
'dupla',
'duplo',
'duque',
'durad',
'duran',
'durar',
'duras',
'duren',
'dures',
'duros',
'ebano',
'ebria',
'ebrio',
'echad',
'echan',
'echar',
'echas',
'echen',
'eches',
'ecuas',
'ecuos',
'edema',
'edila',
'edita',
'edite',
'edito',
'edrad',
'edran',
'edrar',
'edras',
'edren',
'edres',
'educa',
'educe',
'educi',
'educo',
'eduje',
'edujo',
'efebo',
'efeta',
'efeto',
'eflui',
'eforo',
'egena',
'egeno',
'egida',
'eguar',
'eiras',
'ejido',
'ejion',
'ejote',
'elami',
'elata',
'elato',
'elche',
'elega',
'elegi',
'elego',
'elemi',
'elepe',
'eleta',
'eleto',
'eleva',
'eleve',
'elevo',
'elfos',
'elida',
'elide',
'elidi',
'elido',
'elige',
'elija',
'elije',
'elijo',
'elite',
'ellas',
'elles',
'ellos',
'elote',
'eluda',
'elude',
'eludi',
'eludo',
'emana',
'emane',
'emano',
'embai',
'emita',
'emite',
'emiti',
'emito',
'empos',
'empra',
'empre',
'empro',
'emues',
'emula',
'emule',
'emulo',
'enana',
'enano',
'encia',
'eneal',
'eneas',
'eneja',
'eneje',
'enejo',
'enema',
'eneos',
'enero',
'enoja',
'enoje',
'enojo',
'enria',
'enrie',
'enrio',
'ensay',
'enteo',
'entes',
'entra',
'entre',
'entro',
'enula',
'enves',
'envia',
'envie',
'envio',
'enzas',
'eolia',
'eolio',
'eones',
'epale',
'epata',
'epate',
'epato',
'epica',
'epico',
'epoca',
'epoda',
'epodo',
'epota',
'epoto',
'epoxi',
'equis',
'eraba',
'erada',
'erado',
'erais',
'eraje',
'erala',
'erara',
'erare',
'erase',
'erbio',
'ercer',
'erebo',
'ereis',
'ergio',
'ergui',
'erial',
'erias',
'erice',
'erige',
'erigi',
'erija',
'erijo',
'erina',
'erios',
'eriza',
'erizo',
'ermar',
'eroga',
'erogo',
'errad',
'erraj',
'erran',
'errar',
'erras',
'erren',
'erres',
'error',
'erros',
'eruta',
'erute',
'eruto',
'escas',
'escay',
'escoa',
'esmui',
'esnob',
'espay',
'espia',
'espie',
'espin',
'espio',
'esqui',
'estad',
'estan',
'estar',
'estas',
'estay',
'esten',
'ester',
'estes',
'estil',
'estio',
'estol',
'estor',
'estos',
'estoy',
'estro',
'esula',
'etano',
'etapa',
'etica',
'etico',
'etilo',
'etimo',
'etnea',
'etneo',
'etnia',
'etola',
'etolo',
'etusa',
'eubea',
'eubeo',
'euros',
'evada',
'evade',
'evadi',
'evado',
'evita',
'evite',
'evito',
'evoca',
'evoco',
'evohe',
'exida',
'exige',
'exigi',
'exija',
'exijo',
'exila',
'exile',
'exilo',
'exima',
'exime',
'eximi',
'eximo',
'exito',
'exodo',
'exora',
'exore',
'exoro',
'expia',
'expie',
'expio',
'expon',
'extra',
'exuda',
'exude',
'exudo',
'fabas',
'fabla',
'fabos',
'fabro',
'facas',
'facer',
'faces',
'facha',
'fache',
'facho',
'facil',
'facon',
'facto',
'fadas',
'fados',
'faena',
'faene',
'faeno',
'fagos',
'fagot',
'faina',
'faino',
'fajad',
'fajan',
'fajar',
'fajas',
'fajea',
'fajee',
'fajen',
'fajeo',
'fajes',
'fajin',
'fajol',
'fajon',
'fajos',
'falaz',
'falca',
'falce',
'falco',
'falda',
'falla',
'falle',
'fallo',
'falos',
'falsa',
'false',
'falso',
'falta',
'falte',
'falto',
'falua',
'famas',
'fanal',
'fanes',
'fango',
'faqui',
'farad',
'faras',
'farda',
'farde',
'fardo',
'faria',
'fario',
'farol',
'faron',
'faros',
'farpa',
'farra',
'farro',
'farsa',
'farte',
'fases',
'fasol',
'fasos',
'fasta',
'fasto',
'fatal',
'fatas',
'fator',
'fatos',
'fatua',
'fatuo',
'fauna',
'fauno',
'favor',
'favos',
'faxea',
'faxee',
'faxeo',
'faxes',
'fayas',
'febea',
'febeo',
'feble',
'fecal',
'feces',
'fecha',
'feche',
'fecho',
'feeza',
'fejes',
'feliz',
'felon',
'felpa',
'felpe',
'felpo',
'felus',
'femad',
'feman',
'femar',
'femas',
'femen',
'femes',
'femur',
'fenal',
'fenda',
'fendi',
'fenix',
'fenol',
'feral',
'feraz',
'feria',
'ferie',
'ferio',
'ferir',
'fermi',
'feroz',
'ferra',
'ferre',
'ferro',
'ferry',
'festa',
'fetal',
'fetas',
'feten',
'fetor',
'fetos',
'fetua',
'feuca',
'feuco',
'feuda',
'feude',
'feudo',
'feura',
'fiaba',
'fiaca',
'fiada',
'fiado',
'fiais',
'fiana',
'fiara',
'fiare',
'fiase',
'fiats',
'fibra',
'ficar',
'ficen',
'fices',
'ficha',
'fiche',
'ficho',
'ficus',
'fideo',
'fieis',
'fiemo',
'fiera',
'fiero',
'fifad',
'fifan',
'fifar',
'fifas',
'fifen',
'fifes',
'fifis',
'figle',
'figon',
'fijad',
'fijan',
'fijar',
'fijas',
'fijen',
'fijes',
'fijon',
'fijos',
'filad',
'filan',
'filar',
'filas',
'filen',
'files',
'filfa',
'filia',
'filie',
'filin',
'filio',
'filis',
'fillo',
'filma',
'filme',
'filmo',
'films',
'filon',
'filos',
'fimos',
'finad',
'final',
'finan',
'finar',
'finas',
'finca',
'finco',
'finen',
'fines',
'finge',
'fingi',
'finia',
'finid',
'finio',
'finir',
'finis',
'finja',
'finjo',
'finos',
'finta',
'finte',
'finto',
'fique',
'firma',
'firme',
'firmo',
'fisan',
'fisco',
'fisga',
'fisgo',
'fista',
'fisto',
'fizad',
'fizan',
'fizar',
'fizas',
'fizon',
'flaca',
'flaco',
'flama',
'flaon',
'flash',
'flato',
'flava',
'flavo',
'fleco',
'fleja',
'fleje',
'flejo',
'flema',
'fleme',
'fleos',
'fleta',
'flete',
'fleto',
'flexo',
'flipa',
'flipe',
'flipo',
'floja',
'flojo',
'flora',
'flore',
'floro',
'flota',
'flote',
'floto',
'fluia',
'fluid',
'fluir',
'fluis',
'flujo',
'fluor',
'fluya',
'fluye',
'fluyo',
'fobia',
'focal',
'focas',
'focha',
'focia',
'focio',
'focos',
'fofas',
'fofos',
'fogon',
'foisa',
'foiso',
'fojas',
'folga',
'folgo',
'folia',
'folie',
'folio',
'folla',
'folle',
'follo',
'foluz',
'fomes',
'fonda',
'fondo',
'fones',
'fonil',
'fonio',
'fonje',
'fonos',
'foque',
'foral',
'foras',
'forca',
'force',
'forja',
'forje',
'forjo',
'forma',
'forme',
'formo',
'forno',
'foros',
'forra',
'forre',
'forro',
'forte',
'forum',
'forza',
'forzo',
'fosad',
'fosal',
'fosan',
'fosar',
'fosas',
'fosca',
'fosco',
'fosen',
'foses',
'fosil',
'fosor',
'fosos',
'foton',
'fotos',
'fovea',
'fracs',
'frada',
'frade',
'frado',
'fraga',
'frase',
'frece',
'fredo',
'frega',
'frego',
'freia',
'freid',
'freir',
'freis',
'frena',
'frene',
'freno',
'freon',
'freos',
'fresa',
'frese',
'freso',
'freta',
'frete',
'freto',
'freza',
'frezo',
'frian',
'frias',
'frica',
'frico',
'frien',
'fries',
'frior',
'frios',
'frisa',
'frise',
'friso',
'frita',
'frite',
'frito',
'froga',
'frogo',
'frota',
'frote',
'froto',
'fruia',
'fruid',
'fruir',
'fruis',
'fruta',
'frute',
'fruto',
'fruya',
'fruye',
'fruyo',
'fucar',
'fuchi',
'fucia',
'fucos',
'fudre',
'fuego',
'fuera',
'fuere',
'fuero',
'fuesa',
'fuese',
'fuets',
'fufad',
'fufan',
'fufar',
'fufas',
'fufen',
'fufes',
'fufos',
'fufus',
'fugan',
'fugar',
'fugas',
'fugaz',
'fugir',
'fugue',
'fuina',
'fular',
'fulas',
'fulge',
'fulgi',
'fulja',
'fuljo',
'fulla',
'fumad',
'fuman',
'fumar',
'fumas',
'fumen',
'fumes',
'fumon',
'funca',
'funco',
'funda',
'funde',
'fundi',
'fundo',
'funge',
'fungi',
'funja',
'funjo',
'furas',
'furia',
'furor',
'furos',
'furto',
'fusas',
'fusca',
'fusco',
'fusil',
'fusor',
'fusos',
'fusta',
'fuste',
'fusto',
'futil',
'futon',
'futre',
'gaban',
'gabar',
'gacel',
'gacha',
'gache',
'gachi',
'gacho',
'gafad',
'gafan',
'gafar',
'gafas',
'gafea',
'gafee',
'gafen',
'gafeo',
'gafes',
'gafos',
'gagas',
'gagos',
'gaita',
'gajes',
'gajos',
'galan',
'galas',
'galce',
'galea',
'galeo',
'gales',
'galga',
'galgo',
'galio',
'galla',
'galle',
'gallo',
'galon',
'galop',
'galos',
'galua',
'gamas',
'gamba',
'gamma',
'gamon',
'gamos',
'ganad',
'ganan',
'ganar',
'ganas',
'ganen',
'ganes',
'ganga',
'gansa',
'ganso',
'ganta',
'gante',
'garas',
'garay',
'garba',
'garbe',
'garbo',
'garfa',
'garia',
'gario',
'garla',
'garle',
'garlo',
'garma',
'garos',
'garpa',
'garpe',
'garpo',
'garra',
'garre',
'garri',
'garro',
'garua',
'garue',
'garuo',
'garza',
'garzo',
'gasas',
'gasea',
'gasee',
'gaseo',
'gases',
'gason',
'gasta',
'gaste',
'gasto',
'gatas',
'gatea',
'gatee',
'gateo',
'gatos',
'gauss',
'gavia',
'gayad',
'gayan',
'gayar',
'gayas',
'gayen',
'gayes',
'gayos',
'gazas',
'gazna',
'gazne',
'gazno',
'gelan',
'gelar',
'gelas',
'gelen',
'geles',
'gelfe',
'gemas',
'gemia',
'gemid',
'gemir',
'gemis',
'genes',
'genio',
'genol',
'gente',
'geoda',
'gerbo',
'gesta',
'geste',
'gesto',
'getas',
'gibad',
'giban',
'gibao',
'gibar',
'gibas',
'giben',
'gibes',
'gibon',
'gigas',
'gilas',
'giles',
'gilis',
'gilva',
'gilvo',
'giman',
'gimas',
'gimen',
'gimes',
'gimio',
'ginea',
'girad',
'giran',
'girar',
'giras',
'giren',
'gires',
'giros',
'gises',
'giste',
'glase',
'glayo',
'gleba',
'glera',
'glial',
'glias',
'glide',
'glifo',
'globo',
'glosa',
'glose',
'gloso',
'gluma',
'gneis',
'gnomo',
'goben',
'gobio',
'gocen',
'goces',
'gocha',
'gocho',
'godas',
'godeo',
'godos',
'gofas',
'gofio',
'gofos',
'gofra',
'gofre',
'gofro',
'golas',
'golea',
'golee',
'goleo',
'goles',
'golfa',
'golfo',
'golfs',
'golpe',
'gomar',
'gomas',
'gomel',
'gomer',
'gomia',
'gonce',
'gongo',
'gorda',
'gordo',
'gorga',
'gorja',
'gorma',
'gorme',
'gormo',
'gorra',
'gorro',
'gotas',
'gotea',
'gotee',
'goteo',
'goton',
'goyas',
'goyos',
'gozad',
'gozan',
'gozar',
'gozas',
'gozne',
'gozon',
'gozos',
'graba',
'grabe',
'grabo',
'grada',
'grade',
'grado',
'grafo',
'grais',
'graja',
'grajo',
'grama',
'grame',
'gramo',
'grana',
'grand',
'grane',
'grano',
'grant',
'graos',
'grapa',
'grape',
'grapo',
'grasa',
'graso',
'grata',
'grate',
'grato',
'grava',
'grave',
'gravo',
'greba',
'greca',
'greco',
'greda',
'green',
'grelo',
'greno',
'grial',
'grida',
'grifa',
'grife',
'grifo',
'grija',
'grill',
'grima',
'gripa',
'gripe',
'gripo',
'grisa',
'grisu',
'grita',
'grite',
'grito',
'groad',
'groan',
'groar',
'groas',
'groen',
'groes',
'grogs',
'grojo',
'gromo',
'grosa',
'groso',
'gruas',
'gruia',
'gruid',
'gruir',
'gruis',
'gruja',
'gruje',
'gruji',
'grujo',
'grumo',
'grupa',
'grupi',
'grupo',
'gruta',
'gruya',
'gruye',
'gruyo',
'guaba',
'guabo',
'guaca',
'guaco',
'guado',
'guais',
'guaja',
'guaje',
'guala',
'guama',
'guame',
'guamo',
'guano',
'guaos',
'guapa',
'guape',
'guapo',
'guara',
'guare',
'guari',
'guaro',
'guasa',
'guaso',
'guata',
'guate',
'guato',
'guaya',
'guaye',
'guayo',
'gubia',
'guera',
'guero',
'gueto',
'guiad',
'guian',
'guiar',
'guias',
'guida',
'guido',
'guien',
'guies',
'guifa',
'guija',
'guijo',
'guila',
'guilo',
'guina',
'guino',
'guion',
'guipa',
'guipe',
'guipo',
'guira',
'guire',
'guiri',
'guiro',
'guisa',
'guise',
'guiso',
'guita',
'guite',
'guito',
'guiye',
'gujas',
'gulag',
'gular',
'gulas',
'gulay',
'gules',
'gumia',
'gurda',
'gurdo',
'guris',
'gurus',
'gusta',
'guste',
'gusto',
'guzga',
'guzgo',
'guzla',
'habar',
'habas',
'haber',
'habia',
'habil',
'habiz',
'habla',
'hable',
'hablo',
'habon',
'habra',
'habre',
'habus',
'hacan',
'haced',
'hacen',
'hacer',
'haces',
'hacha',
'hache',
'hacho',
'hacia',
'hadar',
'hadas',
'hados',
'hafiz',
'hagan',
'hagas',
'haiga',
'halad',
'halan',
'halar',
'halas',
'halda',
'halen',
'hales',
'halla',
'halle',
'hallo',
'halon',
'halos',
'hamez',
'hampa',
'hampo',
'hanzo',
'hapax',
'haran',
'haras',
'harba',
'harbe',
'harbo',
'harca',
'harda',
'harem',
'haren',
'haria',
'harma',
'haron',
'harpa',
'harre',
'harta',
'harte',
'harto',
'hasta',
'hatea',
'hatee',
'hateo',
'hatos',
'haute',
'havar',
'havos',
'hayal',
'hayan',
'hayas',
'hayos',
'hazas',
'heben',
'hebra',
'heces',
'hecha',
'hecho',
'heded',
'heder',
'hedes',
'hedia',
'hedio',
'hedor',
'helad',
'helar',
'helas',
'helea',
'helee',
'heleo',
'helio',
'helor',
'hemos',
'henal',
'henar',
'hende',
'hendi',
'henil',
'henos',
'henry',
'herba',
'herbe',
'herbo',
'heria',
'herid',
'heril',
'herir',
'heris',
'herma',
'heroe',
'herpe',
'herra',
'herre',
'herro',
'hertz',
'herve',
'hervi',
'hespa',
'hespe',
'hespi',
'hespo',
'hetea',
'heteo',
'hevea',
'hiato',
'hicos',
'hidra',
'hieda',
'hiede',
'hiedo',
'hiela',
'hiele',
'hielo',
'hiena',
'hiera',
'hiere',
'hiero',
'higas',
'higos',
'higui',
'hijas',
'hijea',
'hijee',
'hijeo',
'hijos',
'hilad',
'hilan',
'hilar',
'hilas',
'hilen',
'hiles',
'hilio',
'hilos',
'himen',
'himno',
'himpa',
'himpe',
'himpo',
'hinca',
'hinco',
'hindi',
'hindu',
'hipad',
'hipan',
'hipar',
'hipas',
'hipen',
'hiper',
'hipes',
'hipos',
'hippy',
'hirco',
'hirio',
'hirma',
'hirme',
'hirmo',
'hisca',
'hispa',
'hispe',
'hispi',
'hispo',
'hitad',
'hitan',
'hitar',
'hitas',
'hiten',
'hites',
'hiton',
'hitos',
'hobby',
'hobos',
'hocen',
'hoces',
'hogar',
'hogos',
'hojas',
'hojea',
'hojee',
'hojeo',
'holan',
'holco',
'holea',
'holee',
'holeo',
'holga',
'holgo',
'holla',
'holle',
'hollo',
'homes',
'honda',
'hondo',
'hongo',
'honor',
'honra',
'honre',
'honro',
'hopan',
'hopar',
'hopas',
'hopea',
'hopee',
'hopen',
'hopeo',
'hopes',
'hopos',
'hoque',
'horas',
'horca',
'horco',
'horda',
'horma',
'horna',
'horne',
'horno',
'horra',
'horre',
'horro',
'hosca',
'hosco',
'hospa',
'hoste',
'hotel',
'hotos',
'hoves',
'hoyad',
'hoyan',
'hoyar',
'hoyas',
'hoyen',
'hoyes',
'hoyos',
'hozad',
'hozan',
'hozar',
'hozas',
'huaca',
'huaco',
'huaje',
'huaos',
'hucha',
'hucho',
'hucia',
'hueca',
'hueco',
'huego',
'huela',
'huele',
'huelo',
'huera',
'huero',
'huesa',
'hueso',
'hueva',
'hueve',
'huevo',
'huian',
'huias',
'huich',
'huida',
'huido',
'huifa',
'huila',
'huilo',
'huira',
'huire',
'huiro',
'hulad',
'hulan',
'hular',
'hulas',
'hulea',
'hulee',
'hulen',
'huleo',
'hules',
'hulla',
'hulte',
'humad',
'human',
'humar',
'humas',
'humea',
'humee',
'humen',
'humeo',
'humes',
'humil',
'humor',
'humos',
'humus',
'hunas',
'hunda',
'hunde',
'hundi',
'hundo',
'hunos',
'hupes',
'huras',
'hurga',
'hurgo',
'huris',
'huron',
'hurra',
'hurta',
'hurte',
'hurto',
'husar',
'husma',
'husme',
'husmo',
'husos',
'hutas',
'hutia',
'huyan',
'huyas',
'huyen',
'huyes',
'ibais',
'ibera',
'ibero',
'ibice',
'icaco',
'iceis',
'ichal',
'ichos',
'ichus',
'icono',
'ictus',
'idead',
'ideal',
'idean',
'idear',
'ideas',
'ideay',
'ideen',
'idees',
'ideos',
'idolo',
'iglus',
'ignea',
'igneo',
'igual',
'iguar',
'ijada',
'ijiyo',
'ijuju',
'ileon',
'ileos',
'ilesa',
'ileso',
'ilion',
'ilota',
'iluda',
'ilude',
'iludi',
'iludo',
'ilusa',
'iluso',
'imada',
'imana',
'imane',
'imano',
'imbui',
'imela',
'imita',
'imite',
'imito',
'impar',
'impia',
'impio',
'impla',
'imple',
'implo',
'impon',
'inane',
'incas',
'incoa',
'incoe',
'incoo',
'indas',
'index',
'india',
'indio',
'indos',
'infla',
'infle',
'inflo',
'ingas',
'ingle',
'ingon',
'ingre',
'inope',
'input',
'inris',
'insta',
'inste',
'insto',
'inter',
'intis',
'intui',
'invar',
'iones',
'iotas',
'ipsis',
'irada',
'irado',
'irani',
'ireis',
'irgan',
'irgas',
'irgue',
'irian',
'irias',
'iride',
'irisa',
'irise',
'iriso',
'irrui',
'irupe',
'isbas',
'islam',
'islan',
'islas',
'isleo',
'isoca',
'istmo',
'itala',
'italo',
'items',
'itera',
'itere',
'itero',
'itria',
'itrio',
'itzaj',
'izaba',
'izada',
'izado',
'izais',
'izara',
'izare',
'izase',
'izote',
'jabas',
'jabis',
'jable',
'jabon',
'jabra',
'jabre',
'jabri',
'jabro',
'jacal',
'jacas',
'jacer',
'jacha',
'jacos',
'jacta',
'jacte',
'jacto',
'jadas',
'jadea',
'jadee',
'jadeo',
'jades',
'jadia',
'jadie',
'jadio',
'jaece',
'jaeza',
'jaezo',
'jagua',
'jaiba',
'jaima',
'jajay',
'jalad',
'jalan',
'jalar',
'jalas',
'jalda',
'jalde',
'jaldo',
'jalea',
'jalee',
'jalen',
'jaleo',
'jales',
'jalma',
'jalon',
'jamad',
'jaman',
'jamar',
'jamas',
'jamba',
'jambe',
'jambo',
'jamen',
'james',
'jamon',
'janes',
'japon',
'jaque',
'jaral',
'jaras',
'jarba',
'jarbe',
'jarbo',
'jarca',
'jarda',
'jaros',
'jarra',
'jarre',
'jarro',
'jasad',
'jasan',
'jasar',
'jasas',
'jasen',
'jases',
'jaspe',
'jatas',
'jateo',
'jates',
'jatib',
'jatos',
'jauda',
'jaudo',
'jauja',
'jaula',
'jauta',
'jauto',
'javas',
'javos',
'jayan',
'jebes',
'jedad',
'jedan',
'jedar',
'jedas',
'jeden',
'jedes',
'jefas',
'jefes',
'jeito',
'jejen',
'jeliz',
'jemal',
'jemes',
'jeque',
'jeras',
'jerbo',
'jerez',
'jerga',
'jerpa',
'jetad',
'jetan',
'jetar',
'jetas',
'jetea',
'jetee',
'jeten',
'jeteo',
'jetes',
'jeton',
'jetos',
'jibes',
'jibia',
'jicos',
'jifas',
'jifia',
'jigas',
'jigue',
'jijas',
'jijea',
'jijee',
'jijeo',
'jimad',
'jiman',
'jimar',
'jimas',
'jimen',
'jimes',
'jimia',
'jimio',
'jinda',
'jines',
'jiote',
'jipas',
'jipia',
'jipie',
'jipio',
'jipis',
'jiras',
'jirel',
'jiron',
'jisca',
'jitad',
'jitan',
'jitar',
'jitas',
'jiten',
'jites',
'jobar',
'jobos',
'jocha',
'joche',
'jocho',
'jocon',
'jocos',
'jodan',
'jodas',
'joded',
'joden',
'joder',
'jodes',
'jodia',
'jodio',
'jodon',
'jofor',
'jolin',
'jondo',
'jonia',
'jonio',
'jopan',
'jopar',
'jopas',
'jopea',
'jopee',
'jopen',
'jopeo',
'jopes',
'jopos',
'joras',
'jorco',
'jorfe',
'jorga',
'jorge',
'jorro',
'josas',
'jotas',
'jotes',
'jotos',
'joule',
'joven',
'joyas',
'joyel',
'joyon',
'joyos',
'juana',
'jubas',
'jubon',
'jubos',
'jucas',
'jucos',
'judas',
'judia',
'judio',
'judos',
'juega',
'juego',
'juera',
'jueza',
'jugad',
'jugar',
'jugas',
'jugos',
'jugue',
'jujea',
'jujee',
'jujeo',
'julia',
'julio',
'julos',
'juman',
'jumar',
'jumas',
'jumea',
'jumee',
'jumen',
'jumeo',
'jumes',
'jumil',
'jumos',
'junce',
'junci',
'junco',
'junio',
'junta',
'junte',
'junto',
'junza',
'junzo',
'jupas',
'jupea',
'jupee',
'jupeo',
'jupon',
'jurad',
'juran',
'jurar',
'juras',
'jurco',
'jurel',
'juren',
'jures',
'juros',
'jusis',
'justa',
'juste',
'justo',
'jutas',
'jutia',
'juvia',
'juzga',
'juzgo',
'labeo',
'labes',
'labia',
'labil',
'labio',
'labor',
'labra',
'labre',
'labro',
'lacad',
'lacan',
'lacar',
'lacas',
'lacea',
'lacee',
'lacen',
'laceo',
'laces',
'lacha',
'lacho',
'lacia',
'lacio',
'lacon',
'lacra',
'lacre',
'lacro',
'lacta',
'lacte',
'lacto',
'ladas',
'ladea',
'ladee',
'ladeo',
'ladon',
'lados',
'ladra',
'ladre',
'ladro',
'lagar',
'lagos',
'lagua',
'laica',
'laico',
'laida',
'laido',
'lajas',
'laman',
'lamas',
'lamba',
'lambe',
'lambi',
'lambo',
'lamed',
'lamen',
'lamer',
'lames',
'lamia',
'lamin',
'lamio',
'lampa',
'lampe',
'lampo',
'lanar',
'lanas',
'lance',
'landa',
'lande',
'lando',
'langa',
'lania',
'lanio',
'lanza',
'lanzo',
'lapas',
'lapiz',
'lapon',
'lapos',
'lapsa',
'lapso',
'laque',
'larda',
'larde',
'lardo',
'lares',
'larga',
'largo',
'larra',
'larva',
'lasar',
'lasas',
'lasca',
'lasco',
'laser',
'lasos',
'lasta',
'laste',
'lasto',
'lasun',
'latan',
'latas',
'lataz',
'latea',
'latee',
'laten',
'lateo',
'lates',
'latex',
'latia',
'latid',
'latin',
'latio',
'latir',
'latis',
'laton',
'latos',
'lauda',
'laude',
'laudo',
'launa',
'lauro',
'lauta',
'lauto',
'lavad',
'lavan',
'lavar',
'lavas',
'laven',
'laves',
'laxad',
'laxan',
'laxar',
'laxas',
'laxen',
'laxes',
'laxos',
'layad',
'layan',
'layar',
'layas',
'layen',
'layes',
'lazad',
'lazan',
'lazar',
'lazas',
'lazos',
'leais',
'lecha',
'leche',
'lecho',
'lecos',
'ledas',
'ledon',
'ledos',
'leeis',
'leera',
'leere',
'legad',
'legal',
'legan',
'legar',
'legas',
'legon',
'legos',
'legra',
'legre',
'legro',
'legua',
'legue',
'legui',
'leian',
'leias',
'leida',
'leido',
'leila',
'leima',
'lejas',
'lejia',
'lejio',
'lejos',
'lelas',
'lelos',
'leman',
'lemas',
'lembo',
'lempo',
'lemur',
'lenas',
'lenca',
'lenes',
'lenon',
'lenta',
'lente',
'lento',
'leona',
'lepra',
'leras',
'lerda',
'lerdo',
'lesas',
'lesea',
'lesee',
'leseo',
'lesna',
'lesos',
'leste',
'letal',
'letea',
'leteo',
'leton',
'letra',
'leuco',
'leuda',
'leude',
'leudo',
'levad',
'levan',
'levar',
'levas',
'leven',
'leves',
'leyes',
'lezda',
'lezna',
'lezne',
'liaba',
'liada',
'liado',
'liais',
'liana',
'liara',
'liare',
'liase',
'liaza',
'libad',
'liban',
'libar',
'libas',
'liben',
'liber',
'libes',
'libia',
'libio',
'libon',
'libra',
'libre',
'libro',
'liceo',
'licia',
'licio',
'licor',
'licua',
'licue',
'licuo',
'lider',
'lides',
'lidia',
'lidie',
'lidio',
'lidon',
'liega',
'liego',
'lieis',
'lieva',
'lieve',
'ligad',
'ligan',
'ligar',
'ligas',
'light',
'ligio',
'ligon',
'ligue',
'ligur',
'lijad',
'lijan',
'lijar',
'lijas',
'lijen',
'lijes',
'lilac',
'lilao',
'lilas',
'lilio',
'lilos',
'limad',
'liman',
'limar',
'limas',
'limbo',
'limen',
'limes',
'limon',
'limos',
'linao',
'linar',
'lince',
'linda',
'linde',
'lindo',
'linea',
'linee',
'lineo',
'linfa',
'linio',
'linon',
'linos',
'liosa',
'lioso',
'lipas',
'lipes',
'lipis',
'lipon',
'liras',
'liria',
'lirio',
'liron',
'lisas',
'lises',
'lisia',
'lisie',
'lisio',
'lisis',
'lisol',
'lisos',
'lista',
'liste',
'listo',
'litad',
'litan',
'litar',
'litas',
'liten',
'lites',
'litio',
'litis',
'litre',
'litro',
'lituo',
'liuda',
'liude',
'liudo',
'livor',
'lizas',
'lizos',
'llaca',
'llaga',
'llago',
'llama',
'llame',
'llamo',
'llana',
'llano',
'llapa',
'llape',
'llapo',
'llave',
'lleca',
'lleco',
'llega',
'llego',
'llena',
'llene',
'lleno',
'llera',
'lleva',
'lleve',
'llevo',
'llora',
'llore',
'lloro',
'llosa',
'llove',
'llovi',
'loaba',
'loada',
'loado',
'loais',
'loara',
'loare',
'loase',
'lobas',
'lobby',
'lobea',
'lobee',
'lobeo',
'lobos',
'local',
'locas',
'locea',
'locee',
'loceo',
'locha',
'loche',
'locos',
'locro',
'lodon',
'lodos',
'lodra',
'loeis',
'logar',
'logia',
'logis',
'logos',
'logra',
'logre',
'logro',
'loica',
'loina',
'loino',
'lolas',
'lolea',
'lolee',
'loleo',
'lolio',
'lolis',
'lolos',
'lomas',
'lomba',
'lombo',
'lomea',
'lomee',
'lomeo',
'lomos',
'lonas',
'lonco',
'longa',
'longo',
'lonja',
'loras',
'lorea',
'loree',
'loreo',
'lores',
'loros',
'lorza',
'losad',
'losan',
'losar',
'losas',
'losen',
'loses',
'lotas',
'lotea',
'lotee',
'loteo',
'lotes',
'lotin',
'lotos',
'lozas',
'lucas',
'lucen',
'luces',
'lucha',
'luche',
'lucho',
'lucia',
'lucid',
'lucio',
'lucir',
'lucis',
'lucra',
'lucre',
'lucro',
'ludan',
'ludas',
'luden',
'ludes',
'ludia',
'ludid',
'ludie',
'ludio',
'ludir',
'ludis',
'ludos',
'luego',
'lugar',
'lugre',
'luian',
'luias',
'luida',
'luido',
'luira',
'luire',
'luisa',
'lujad',
'lujan',
'lujar',
'lujas',
'lujen',
'lujes',
'lujos',
'lulos',
'lulus',
'lumas',
'lumbo',
'lumen',
'lumia',
'lunar',
'lunas',
'lunch',
'lunea',
'lunee',
'lunel',
'luneo',
'lunes',
'lunfa',
'lupas',
'lupia',
'lupus',
'lurte',
'lusas',
'lusca',
'lusco',
'lusos',
'lutea',
'luteo',
'lutos',
'luvia',
'luxad',
'luxan',
'luxar',
'luxas',
'luxen',
'luxes',
'luyan',
'luyas',
'luyen',
'luyes',
'luzca',
'luzco',
'lycra',
'mabis',
'mable',
'macal',
'macan',
'macar',
'macas',
'macea',
'macee',
'macen',
'maceo',
'maces',
'macha',
'mache',
'machi',
'macho',
'macia',
'macio',
'macis',
'macla',
'macon',
'macro',
'macua',
'mador',
'madre',
'maesa',
'maese',
'maeso',
'mafia',
'magas',
'magia',
'magie',
'magin',
'magio',
'magma',
'magna',
'magno',
'magos',
'magra',
'magro',
'mague',
'mahon',
'maido',
'majad',
'majal',
'majan',
'majar',
'majas',
'majea',
'majee',
'majen',
'majeo',
'majes',
'majos',
'malar',
'malas',
'malea',
'malee',
'maleo',
'males',
'malis',
'malla',
'malle',
'mallo',
'malon',
'malos',
'malta',
'malva',
'malve',
'malvo',
'mamad',
'maman',
'mamar',
'mamas',
'mambi',
'mambo',
'mamen',
'mames',
'mamey',
'mamia',
'mamon',
'mamua',
'mamut',
'manad',
'manal',
'manan',
'manar',
'manas',
'manca',
'manco',
'manda',
'mande',
'mandi',
'mando',
'manea',
'manee',
'manen',
'maneo',
'manes',
'manga',
'mango',
'mania',
'manid',
'manio',
'manir',
'manis',
'manos',
'mansa',
'manso',
'manta',
'manto',
'manus',
'maoma',
'maori',
'mapas',
'mapea',
'mapee',
'mapeo',
'mapos',
'maque',
'maqui',
'maras',
'marca',
'marce',
'marci',
'marco',
'marea',
'maree',
'mareo',
'mares',
'marga',
'margo',
'maria',
'marlo',
'maron',
'maros',
'marra',
'marre',
'marro',
'marsa',
'marso',
'marta',
'marte',
'marza',
'marzo',
'masad',
'masan',
'masar',
'masas',
'masca',
'masco',
'masea',
'masee',
'masen',
'maseo',
'mases',
'masia',
'maslo',
'mason',
'maste',
'masto',
'matad',
'matan',
'matar',
'matas',
'matea',
'matee',
'maten',
'mateo',
'mates',
'matiz',
'maton',
'matos',
'matul',
'maula',
'maule',
'maulo',
'maura',
'maure',
'mauro',
'mayad',
'mayal',
'mayan',
'mayar',
'mayas',
'mayea',
'mayee',
'mayen',
'mayeo',
'mayes',
'mayor',
'mayos',
'mazad',
'mazan',
'mazar',
'mazas',
'mazna',
'mazne',
'mazno',
'mazos',
'mbaya',
'meaba',
'meada',
'meado',
'meais',
'meaja',
'meano',
'meara',
'meare',
'mease',
'meato',
'mecas',
'meced',
'mecen',
'mecer',
'meces',
'mecha',
'meche',
'mecho',
'mecia',
'mecio',
'mecos',
'medas',
'media',
'medid',
'medie',
'medio',
'medir',
'medis',
'medos',
'medra',
'medre',
'medro',
'meeis',
'megas',
'megos',
'meiga',
'meigo',
'mejan',
'mejas',
'mejed',
'mejen',
'mejer',
'mejes',
'mejia',
'mejio',
'mejor',
'melad',
'melar',
'melas',
'melca',
'melga',
'melgo',
'melis',
'mella',
'melle',
'mello',
'melon',
'melsa',
'melva',
'memas',
'memez',
'memos',
'menad',
'menan',
'menar',
'menas',
'menda',
'menea',
'menee',
'menen',
'meneo',
'menes',
'menge',
'menor',
'menos',
'mensa',
'menso',
'mensu',
'menta',
'mente',
'menti',
'mento',
'menus',
'meona',
'merad',
'meran',
'merar',
'meras',
'merca',
'merco',
'meren',
'meres',
'merey',
'mergo',
'merla',
'merlo',
'merma',
'merme',
'mermo',
'merol',
'meros',
'mersa',
'mesad',
'mesan',
'mesar',
'mesas',
'mesen',
'meses',
'mesma',
'mesmo',
'meson',
'mesta',
'mesto',
'metad',
'metal',
'metan',
'metas',
'meted',
'meten',
'meter',
'metes',
'metia',
'metio',
'metra',
'metro',
'meyas',
'meyor',
'mezan',
'mezas',
'miaba',
'miado',
'miaga',
'miago',
'miais',
'miaja',
'miara',
'miare',
'miase',
'miaus',
'micas',
'micer',
'mices',
'micha',
'miche',
'micho',
'micos',
'micra',
'micro',
'midan',
'midas',
'miden',
'mides',
'midio',
'miedo',
'mieis',
'miela',
'miele',
'mielo',
'miera',
'migad',
'migan',
'migar',
'migas',
'migra',
'migre',
'migro',
'migue',
'mijos',
'milan',
'miles',
'milis',
'milla',
'millo',
'milpa',
'mimad',
'miman',
'mimar',
'mimas',
'mimen',
'mimes',
'mimos',
'minad',
'minal',
'minan',
'minar',
'minas',
'minaz',
'minen',
'mines',
'minga',
'mingo',
'minia',
'minie',
'minio',
'minue',
'mioma',
'miona',
'miope',
'mirad',
'miran',
'mirar',
'miras',
'miren',
'mires',
'mirla',
'mirle',
'mirlo',
'miron',
'mirra',
'mirto',
'mirza',
'misad',
'misal',
'misan',
'misar',
'misas',
'misen',
'mises',
'misia',
'misil',
'misio',
'misma',
'mismo',
'mista',
'miste',
'misto',
'mitad',
'mitan',
'mitas',
'mitin',
'miton',
'mitos',
'mitra',
'mitre',
'mitro',
'miura',
'mixta',
'mixto',
'mizas',
'mizos',
'moais',
'moare',
'moble',
'mocad',
'mocan',
'mocar',
'mocas',
'mocea',
'mocee',
'moceo',
'mocha',
'moche',
'mocho',
'mocil',
'mocos',
'modal',
'modas',
'modem',
'modio',
'modos',
'mofad',
'mofan',
'mofar',
'mofas',
'mofen',
'mofes',
'mogas',
'mogol',
'mogon',
'mogos',
'mohin',
'mohos',
'mohur',
'mojad',
'mojan',
'mojar',
'mojas',
'mojel',
'mojen',
'mojes',
'mojil',
'mojis',
'mojon',
'mojos',
'molad',
'molan',
'molar',
'molas',
'molda',
'molde',
'moldo',
'moled',
'molen',
'moler',
'moles',
'molia',
'molio',
'molla',
'molle',
'molon',
'molos',
'molsa',
'molso',
'momea',
'momee',
'momeo',
'momia',
'momio',
'momos',
'monas',
'monda',
'monde',
'mondo',
'monea',
'monee',
'moneo',
'monfi',
'monga',
'mongo',
'monis',
'monja',
'monje',
'monos',
'monra',
'monse',
'monta',
'monte',
'monto',
'mopan',
'mopas',
'moque',
'morad',
'moral',
'moran',
'morar',
'moras',
'morbo',
'morca',
'morco',
'morde',
'mordi',
'morea',
'moren',
'moreo',
'mores',
'morfa',
'morfe',
'morfo',
'morga',
'moria',
'morid',
'morir',
'moris',
'morma',
'morme',
'mormo',
'moron',
'moros',
'morra',
'morro',
'morsa',
'morse',
'mosca',
'mosco',
'mosen',
'moste',
'mosto',
'motas',
'motea',
'motee',
'motel',
'moteo',
'motes',
'motil',
'motin',
'moton',
'motor',
'motos',
'moved',
'mover',
'moves',
'movia',
'movil',
'movio',
'moxas',
'moxte',
'moyas',
'moyos',
'mozas',
'mozos',
'muare',
'muble',
'mucas',
'mucha',
'mucho',
'mucos',
'mudad',
'mudan',
'mudar',
'mudas',
'muden',
'mudes',
'mudez',
'mudos',
'mueca',
'muela',
'muele',
'muelo',
'muera',
'muere',
'muero',
'mueso',
'mueva',
'mueve',
'muevo',
'mufas',
'mufla',
'mufti',
'mugad',
'mugan',
'mugar',
'mugas',
'mugen',
'muges',
'mugia',
'mugid',
'mugil',
'mugio',
'mugir',
'mugis',
'mugle',
'mugor',
'mugre',
'mugue',
'muian',
'muias',
'muida',
'muido',
'muira',
'muire',
'mujan',
'mujas',
'mujer',
'mujol',
'mular',
'mulas',
'muleo',
'mules',
'mulla',
'mulle',
'mulli',
'mullo',
'mulos',
'mulsa',
'mulso',
'multa',
'multe',
'multo',
'munas',
'mundo',
'murad',
'mural',
'muran',
'murar',
'muras',
'muren',
'mures',
'murga',
'muria',
'murio',
'muros',
'murta',
'murto',
'musan',
'musar',
'musas',
'musca',
'musco',
'musen',
'museo',
'muses',
'musga',
'musgo',
'musia',
'musio',
'musir',
'musis',
'muslo',
'musos',
'mutad',
'mutan',
'mutar',
'mutas',
'muten',
'mutes',
'mutis',
'mutra',
'mutro',
'mutua',
'mutuo',
'muyan',
'muyas',
'muyen',
'muyes',
'nabab',
'nabal',
'nabar',
'nabas',
'nabis',
'nabla',
'nabos',
'nacar',
'nacas',
'naced',
'nacen',
'nacer',
'naces',
'nacha',
'nacho',
'nacia',
'nacio',
'nacos',
'nacre',
'nadad',
'nadal',
'nadan',
'nadar',
'nadas',
'naden',
'nades',
'nadga',
'nadie',
'nadir',
'nafra',
'nafre',
'nafro',
'nafta',
'nagua',
'nahoa',
'nahua',
'naife',
'naifs',
'naipe',
'naire',
'najas',
'nalca',
'nalga',
'nanas',
'nanay',
'nance',
'nanea',
'nanee',
'naneo',
'nansa',
'nansu',
'nanta',
'nante',
'nanto',
'napas',
'napea',
'napeo',
'naque',
'narco',
'nardo',
'nares',
'nariz',
'narra',
'narre',
'narro',
'nasal',
'nasas',
'nason',
'nasos',
'natal',
'natas',
'natia',
'natio',
'natos',
'natri',
'nauta',
'naval',
'navas',
'naves',
'navio',
'nazca',
'nazco',
'nazis',
'nebel',
'nebis',
'nebli',
'nebro',
'necea',
'necee',
'neceo',
'necia',
'necio',
'negad',
'negar',
'negas',
'negra',
'negro',
'negue',
'negus',
'nejas',
'nejos',
'neldo',
'neles',
'nemas',
'nemea',
'nemeo',
'nemes',
'nemon',
'nenas',
'nenes',
'nenia',
'nepes',
'neron',
'nesga',
'nesgo',
'netas',
'netos',
'neuma',
'nevad',
'nevar',
'nevas',
'nevos',
'nevus',
'nexos',
'niara',
'niazo',
'niche',
'nicho',
'nicle',
'nicol',
'nidal',
'nidia',
'nidio',
'nidos',
'niega',
'niego',
'niela',
'niele',
'nielo',
'nieta',
'nieto',
'nieva',
'nieve',
'nievo',
'nigua',
'nilad',
'nilon',
'nimba',
'nimbe',
'nimbo',
'nimia',
'nimio',
'ninfa',
'ninfo',
'ninot',
'nioto',
'nipas',
'nipis',
'nipon',
'nipos',
'niqui',
'niste',
'nitor',
'nitos',
'nitra',
'nitre',
'nitro',
'nivea',
'nivel',
'niveo',
'nixte',
'nobel',
'noble',
'nocas',
'noche',
'nocir',
'nocla',
'nodal',
'nodos',
'noema',
'nogal',
'nolis',
'nolit',
'nomas',
'nomon',
'nomos',
'nonas',
'nones',
'nonio',
'nonos',
'nopal',
'noque',
'noray',
'noria',
'norma',
'norme',
'normo',
'norte',
'notad',
'notan',
'notar',
'notas',
'noten',
'notes',
'notos',
'notro',
'novad',
'noval',
'novan',
'novar',
'novas',
'novel',
'noven',
'noves',
'novia',
'novie',
'novio',
'noyos',
'nubes',
'nubia',
'nubil',
'nubio',
'nubla',
'nuble',
'nublo',
'nucas',
'nuche',
'nucir',
'nucos',
'nudas',
'nudos',
'nuera',
'nuesa',
'nueso',
'nueva',
'nueve',
'nuevo',
'nueza',
'nulas',
'nulos',
'numen',
'numos',
'nunca',
'nutra',
'nutre',
'nutri',
'nutro',
'oasis',
'obelo',
'obesa',
'obeso',
'obice',
'obito',
'oblea',
'oboes',
'obolo',
'obrad',
'obran',
'obrar',
'obras',
'obren',
'obres',
'obsta',
'obste',
'obsto',
'obten',
'obues',
'obvia',
'obvie',
'obvio',
'ocapi',
'ocaso',
'ocelo',
'ocena',
'ochos',
'ociad',
'ocian',
'ociar',
'ocias',
'ocien',
'ocies',
'ocios',
'ocles',
'oclui',
'ocote',
'ocras',
'ocres',
'ocrey',
'ocuje',
'ocume',
'ocumo',
'ocupa',
'ocupe',
'ocupo',
'odeon',
'odiad',
'odian',
'odiar',
'odias',
'odien',
'odies',
'odios',
'odres',
'oeste',
'ofita',
'ogros',
'ohmio',
'oiais',
'oible',
'oidas',
'oidio',
'oidor',
'oidos',
'oigan',
'oigas',
'oimos',
'oiran',
'oiras',
'oiria',
'oislo',
'oiste',
'ojala',
'ojale',
'ojalo',
'ojead',
'ojean',
'ojear',
'ojeas',
'ojeen',
'ojees',
'ojeos',
'ojera',
'ojete',
'ojito',
'ojiva',
'ojosa',
'ojoso',
'ojota',
'ojuda',
'ojudo',
'olais',
'olaje',
'olead',
'olean',
'olear',
'oleas',
'oleen',
'olees',
'oleis',
'oleos',
'olera',
'olere',
'olian',
'olias',
'olida',
'olido',
'olios',
'oliva',
'olive',
'olivo',
'ollao',
'ollar',
'ollas',
'olmas',
'olmos',
'olote',
'olura',
'omani',
'omaso',
'ombus',
'omega',
'omero',
'omeya',
'omina',
'omine',
'omino',
'omisa',
'omiso',
'omita',
'omite',
'omiti',
'omito',
'omoto',
'oncea',
'oncee',
'onceo',
'onces',
'ondas',
'ondea',
'ondee',
'ondeo',
'ondra',
'onece',
'oneci',
'onice',
'onoto',
'onzas',
'opaca',
'opaco',
'opada',
'opado',
'opalo',
'opera',
'opere',
'opero',
'opila',
'opile',
'opilo',
'opima',
'opimo',
'opina',
'opine',
'opino',
'opios',
'opone',
'optad',
'optan',
'optar',
'optas',
'opten',
'optes',
'opuse',
'opuso',
'oraba',
'orada',
'orado',
'orais',
'oraje',
'orale',
'orara',
'orare',
'orase',
'orate',
'orbes',
'orcas',
'orcen',
'orces',
'orcos',
'orden',
'oread',
'orean',
'orear',
'oreas',
'oreen',
'orees',
'oreis',
'oreja',
'oreos',
'orero',
'orfos',
'orfre',
'orgia',
'oribe',
'ories',
'orina',
'orine',
'orino',
'oriol',
'orive',
'orlad',
'orlan',
'orlar',
'orlas',
'orlen',
'orles',
'orlos',
'ornad',
'ornan',
'ornar',
'ornas',
'ornea',
'ornee',
'ornen',
'orneo',
'ornes',
'oroya',
'ortos',
'oruga',
'orujo',
'orzad',
'orzan',
'orzar',
'orzas',
'osaba',
'osada',
'osado',
'osais',
'osara',
'osare',
'osase',
'oscas',
'oscos',
'osead',
'osean',
'osear',
'oseas',
'oseen',
'osees',
'oseis',
'oseos',
'osera',
'osero',
'oseta',
'osmio',
'ososa',
'ososo',
'ostas',
'ostia',
'ostra',
'ostro',
'osuda',
'osudo',
'osuna',
'osuno',
'otate',
'otead',
'otean',
'otear',
'oteas',
'oteen',
'otees',
'otero',
'otila',
'otile',
'otilo',
'otoba',
'otras',
'otres',
'otris',
'otros',
'ovaba',
'ovada',
'ovado',
'ovais',
'ovala',
'ovale',
'ovalo',
'ovara',
'ovare',
'ovase',
'oveis',
'oveja',
'overa',
'overo',
'ovido',
'ovina',
'ovino',
'ovnis',
'ovolo',
'ovosa',
'ovoso',
'ovula',
'ovule',
'ovulo',
'oxead',
'oxean',
'oxear',
'oxeas',
'oxeen',
'oxees',
'oxida',
'oxide',
'oxido',
'oyera',
'oyere',
'oyese',
'ozena',
'ozona',
'ozono',
'pacae',
'pacas',
'pacay',
'paced',
'pacen',
'pacer',
'paces',
'pacha',
'pacho',
'pacia',
'pacio',
'pacon',
'pacos',
'pacta',
'pacte',
'pacto',
'pacus',
'padre',
'pafia',
'pafio',
'pagad',
'pagan',
'pagar',
'pagas',
'pagel',
'pagos',
'pagro',
'pagua',
'pague',
'pahua',
'paico',
'paila',
'paina',
'paira',
'paire',
'pairo',
'pajar',
'pajas',
'pajea',
'pajee',
'pajel',
'pajeo',
'pajes',
'pajil',
'pajla',
'pajon',
'pajos',
'pajuz',
'palas',
'palay',
'palca',
'palco',
'palea',
'palee',
'paleo',
'pales',
'palia',
'palie',
'palio',
'palis',
'palla',
'palle',
'pallo',
'palma',
'palme',
'palmo',
'palon',
'palor',
'palos',
'palpa',
'palpe',
'palpi',
'palpo',
'palta',
'palto',
'pamba',
'pampa',
'pamue',
'panal',
'panas',
'panca',
'panco',
'panda',
'pando',
'panel',
'panes',
'panga',
'panji',
'panos',
'pansa',
'panty',
'panul',
'panza',
'papad',
'papal',
'papan',
'papar',
'papas',
'papaz',
'papea',
'papee',
'papel',
'papen',
'papeo',
'papes',
'papin',
'papon',
'papos',
'papua',
'papus',
'parad',
'paral',
'paran',
'parao',
'parar',
'paras',
'parca',
'parce',
'parco',
'parda',
'pardo',
'parea',
'pared',
'paree',
'parel',
'paren',
'pareo',
'pares',
'pargo',
'paria',
'parid',
'pario',
'parir',
'paris',
'parla',
'parle',
'parlo',
'parne',
'paros',
'parpa',
'parpe',
'parpo',
'parra',
'parre',
'parro',
'parsi',
'parta',
'parte',
'parti',
'parto',
'parva',
'parvo',
'pasad',
'pasan',
'pasar',
'pasas',
'pasco',
'pasea',
'pasee',
'pasen',
'paseo',
'pases',
'pasil',
'pasma',
'pasme',
'pasmo',
'pasos',
'paspa',
'paspe',
'paspo',
'pasta',
'paste',
'pasto',
'patan',
'patao',
'patas',
'patax',
'patay',
'patea',
'patee',
'pateo',
'pater',
'pates',
'patin',
'patio',
'patis',
'paton',
'patos',
'pauji',
'paula',
'paule',
'paulo',
'pausa',
'pause',
'pauso',
'pauta',
'paute',
'pauto',
'pavas',
'paves',
'pavia',
'pavon',
'pavor',
'pavos',
'paxte',
'payad',
'payan',
'payar',
'payas',
'payen',
'payes',
'payos',
'pazca',
'pazco',
'pazos',
'peais',
'peaje',
'peala',
'peale',
'pealo',
'peana',
'pebre',
'pecad',
'pecan',
'pecar',
'pecas',
'peces',
'pecha',
'peche',
'pecho',
'pecio',
'pecta',
'pecte',
'pecto',
'pedal',
'pedia',
'pedid',
'pedio',
'pedir',
'pedis',
'pedos',
'pedro',
'peeis',
'peera',
'peere',
'pegad',
'pegan',
'pegar',
'pegas',
'pegon',
'pegos',
'pegue',
'peian',
'peias',
'peido',
'peina',
'peine',
'peino',
'pejes',
'pejin',
'pelad',
'pelan',
'pelar',
'pelas',
'pelde',
'pelea',
'pelee',
'pelen',
'peleo',
'peles',
'pelis',
'pella',
'pello',
'pelma',
'pelon',
'pelos',
'pelta',
'pelus',
'pelvi',
'pemon',
'penad',
'penal',
'penan',
'penar',
'penas',
'penca',
'penco',
'penda',
'pende',
'pendi',
'pendo',
'penen',
'penes',
'penis',
'penol',
'penos',
'pensa',
'pense',
'penso',
'peora',
'pepas',
'pepes',
'pepla',
'peplo',
'pepon',
'pepus',
'peque',
'peral',
'peras',
'perca',
'perde',
'perdi',
'peris',
'perla',
'perle',
'perlo',
'perna',
'perno',
'perol',
'peros',
'perra',
'perro',
'persa',
'perta',
'perus',
'pesad',
'pesan',
'pesar',
'pesas',
'pesca',
'pesce',
'pesco',
'pesen',
'peses',
'pesga',
'pesgo',
'pesia',
'pesol',
'pesor',
'pesos',
'peste',
'petad',
'petan',
'petar',
'petas',
'peten',
'petes',
'petos',
'petra',
'peuco',
'peumo',
'pezon',
'piaba',
'piada',
'piado',
'piafa',
'piafe',
'piafo',
'piais',
'piala',
'piale',
'pialo',
'piano',
'piara',
'piare',
'piase',
'pibas',
'pibes',
'pibil',
'picad',
'pical',
'pican',
'picar',
'picas',
'picea',
'piceo',
'picha',
'piche',
'pichi',
'picho',
'picon',
'picor',
'picos',
'picuy',
'pidan',
'pidas',
'piden',
'pides',
'pidio',
'pidon',
'pieis',
'piejo',
'pieza',
'pifas',
'pifia',
'pifie',
'pifio',
'pigra',
'pigre',
'pigro',
'pigua',
'pihua',
'pijas',
'pijes',
'pijin',
'pijos',
'pijul',
'pijuy',
'pilad',
'pilan',
'pilar',
'pilas',
'pilca',
'pilen',
'pileo',
'piles',
'pilla',
'pille',
'pillo',
'pilme',
'pilon',
'pilos',
'pinal',
'pinar',
'pinas',
'pince',
'pines',
'pinga',
'pingo',
'pinna',
'pinol',
'pinos',
'pinta',
'pinte',
'pinto',
'pinza',
'pinzo',
'piojo',
'piola',
'piole',
'piolo',
'piona',
'pipad',
'pipan',
'pipar',
'pipas',
'pipen',
'pipes',
'pipia',
'pipie',
'pipil',
'pipio',
'pipis',
'pipon',
'pipos',
'pique',
'pirad',
'piral',
'piran',
'pirar',
'piras',
'pirca',
'pirco',
'piren',
'pires',
'pirla',
'piron',
'piros',
'pirra',
'pirre',
'pirri',
'pirro',
'pirul',
'pirus',
'pisad',
'pisan',
'pisar',
'pisas',
'pisca',
'pisco',
'pisen',
'pises',
'pison',
'pisos',
'pispa',
'pispe',
'pispo',
'pista',
'piste',
'pisto',
'pitad',
'pital',
'pitan',
'pitao',
'pitar',
'pitas',
'pitea',
'pitee',
'piten',
'piteo',
'pites',
'pitia',
'pitio',
'pitis',
'piton',
'pitos',
'piula',
'piule',
'piulo',
'piune',
'piure',
'pivot',
'pixel',
'pizca',
'pizco',
'pizza',
'placa',
'place',
'placi',
'placo',
'plaga',
'plago',
'plana',
'plano',
'plata',
'plato',
'playa',
'playe',
'playo',
'plaza',
'plazo',
'plebe',
'pleca',
'plega',
'plego',
'plena',
'pleno',
'pleon',
'plepa',
'plexo',
'plica',
'plisa',
'plise',
'pliso',
'ploma',
'plome',
'plomo',
'plugo',
'pluma',
'pobla',
'poble',
'poblo',
'pobos',
'pobra',
'pobre',
'pocas',
'pocha',
'pocho',
'pocos',
'podad',
'podal',
'podan',
'podar',
'podas',
'poded',
'poden',
'poder',
'podes',
'podia',
'podio',
'podon',
'podra',
'podre',
'poema',
'poeta',
'poino',
'poisa',
'poise',
'polar',
'polca',
'polco',
'polea',
'polen',
'poleo',
'polex',
'polin',
'polio',
'polir',
'polis',
'polla',
'pollo',
'polos',
'polvo',
'pomar',
'pomas',
'pomez',
'pomos',
'pompa',
'pompo',
'ponci',
'poned',
'ponen',
'poner',
'pones',
'poney',
'ponga',
'pongo',
'ponia',
'ponis',
'ponto',
'popad',
'popan',
'popar',
'popas',
'popel',
'popen',
'popes',
'popos',
'popti',
'porco',
'porga',
'porgo',
'porno',
'poros',
'porra',
'porro',
'porta',
'porte',
'porto',
'posad',
'posan',
'posar',
'posas',
'posca',
'posea',
'posee',
'posei',
'posen',
'poseo',
'poses',
'posma',
'posmo',
'poson',
'posos',
'posta',
'poste',
'potad',
'potan',
'potar',
'potas',
'potea',
'potee',
'poten',
'poteo',
'potes',
'potos',
'potra',
'potro',
'poyad',
'poyal',
'poyan',
'poyar',
'poyas',
'poyen',
'poyes',
'poyos',
'pozal',
'pozas',
'pozol',
'pozos',
'prado',
'praos',
'prava',
'pravo',
'praza',
'prear',
'preas',
'preda',
'presa',
'preso',
'prest',
'preve',
'previ',
'prima',
'prime',
'primo',
'prion',
'prior',
'prisa',
'priva',
'prive',
'privo',
'proal',
'proas',
'proba',
'probe',
'probo',
'proco',
'proel',
'profa',
'profe',
'prois',
'proiz',
'prole',
'prona',
'prono',
'prora',
'prosa',
'pruna',
'pruno',
'psies',
'puaba',
'puada',
'puado',
'puais',
'puara',
'puare',
'puase',
'puber',
'pubes',
'pubis',
'pucha',
'pucho',
'pucia',
'pudin',
'pudio',
'pudir',
'pudor',
'pudra',
'pudre',
'pudri',
'pudro',
'pudus',
'pueda',
'puede',
'puedo',
'pueis',
'pufos',
'pugas',
'pugil',
'pugna',
'pugne',
'pugno',
'pujad',
'pujan',
'pujar',
'pujas',
'pujen',
'pujes',
'pujos',
'pulan',
'pulas',
'pulen',
'pules',
'pulga',
'pulia',
'pulid',
'pulio',
'pulir',
'pulis',
'pulla',
'pulle',
'pullo',
'pulpa',
'pulpo',
'pulsa',
'pulse',
'pulso',
'pumas',
'pumba',
'punan',
'punar',
'punas',
'punce',
'punen',
'punes',
'punga',
'punge',
'pungi',
'punia',
'punid',
'punio',
'punir',
'punis',
'punja',
'punjo',
'punta',
'punte',
'punto',
'punza',
'punzo',
'pupad',
'pupan',
'pupar',
'pupas',
'pupen',
'pupes',
'pupos',
'pupus',
'puras',
'purea',
'puree',
'pureo',
'pures',
'purga',
'purgo',
'purin',
'puros',
'purra',
'purre',
'purri',
'purro',
'puses',
'puspa',
'puspo',
'putal',
'putas',
'putea',
'putee',
'puteo',
'puton',
'putos',
'puyad',
'puyan',
'puyar',
'puyas',
'puyen',
'puyes',
'puyon',
'puyos',
'puzle',
'puzol',
'pymes',
'queco',
'queda',
'quede',
'quedo',
'queja',
'queje',
'quejo',
'quema',
'queme',
'quemi',
'quemo',
'quena',
'quepa',
'quepi',
'quepo',
'quera',
'quere',
'quero',
'queso',
'quias',
'quien',
'quier',
'quifs',
'quijo',
'quila',
'quilo',
'quima',
'quimo',
'quina',
'quino',
'quios',
'quipa',
'quipu',
'quisa',
'quise',
'quiso',
'quita',
'quite',
'quito',
'quitu',
'quivi',
'quiza',
'rabal',
'rabas',
'rabea',
'rabee',
'rabel',
'rabeo',
'rabia',
'rabie',
'rabil',
'rabio',
'rabis',
'rabon',
'rabos',
'racea',
'racee',
'racel',
'raceo',
'racha',
'rache',
'racho',
'racor',
'racos',
'radal',
'radar',
'radas',
'rades',
'radia',
'radie',
'radio',
'radon',
'raeis',
'raera',
'raere',
'rafal',
'rafas',
'rafea',
'rafee',
'rafeo',
'rafes',
'rafez',
'rafia',
'ragua',
'ragus',
'rahez',
'raian',
'raias',
'raice',
'raida',
'raido',
'raiga',
'raigo',
'raijo',
'raiza',
'raizo',
'rajad',
'rajan',
'rajar',
'rajas',
'rajen',
'rajes',
'rajon',
'ralas',
'ralba',
'ralbe',
'ralbo',
'ralea',
'ralee',
'raleo',
'ralla',
'ralle',
'rallo',
'rally',
'ralos',
'ralva',
'ralve',
'ralvo',
'ramal',
'ramas',
'ramea',
'ramee',
'rameo',
'ramio',
'ramon',
'ramos',
'rampa',
'rampe',
'rampo',
'ranas',
'randa',
'rands',
'rango',
'ranos',
'rapad',
'rapan',
'rapar',
'rapas',
'rapaz',
'rapen',
'rapes',
'rapos',
'rapta',
'rapte',
'rapto',
'raque',
'raras',
'rarea',
'raree',
'rareo',
'raros',
'rasad',
'rasan',
'rasar',
'rasas',
'rasca',
'rasco',
'rasel',
'rasen',
'rases',
'rasga',
'rasgo',
'rasis',
'rasos',
'raspa',
'raspe',
'raspo',
'ratas',
'ratea',
'ratee',
'rateo',
'ratio',
'raton',
'ratos',
'rauca',
'rauco',
'rauda',
'raudo',
'rauli',
'rauta',
'rayad',
'rayan',
'rayar',
'rayas',
'rayen',
'rayes',
'rayon',
'rayos',
'razar',
'razas',
'razia',
'razon',
'reaje',
'reala',
'reama',
'reame',
'reamo',
'reara',
'reare',
'rearo',
'reata',
'reate',
'reato',
'rebla',
'reble',
'reblo',
'rebol',
'rebus',
'recae',
'recai',
'recel',
'recen',
'reces',
'recia',
'recio',
'recle',
'recre',
'recta',
'recte',
'recto',
'recua',
'redad',
'redan',
'redar',
'redas',
'redel',
'reden',
'redes',
'redil',
'redol',
'redor',
'redro',
'refez',
'regad',
'regar',
'regas',
'regia',
'regid',
'regio',
'regir',
'regis',
'regla',
'regle',
'reglo',
'regue',
'rehaz',
'rehen',
'rehui',
'rehus',
'reian',
'reias',
'reida',
'reido',
'reila',
'reile',
'reilo',
'reina',
'reine',
'reino',
'reira',
'reire',
'rejal',
'rejas',
'rejin',
'rejon',
'rejos',
'rejus',
'relax',
'relea',
'relee',
'relei',
'relej',
'releo',
'reles',
'reloj',
'relsa',
'relso',
'relva',
'relve',
'relvo',
'remad',
'reman',
'remar',
'remas',
'remen',
'remes',
'remos',
'renal',
'renca',
'renco',
'renda',
'rende',
'rendi',
'rendo',
'renes',
'renga',
'rengo',
'renil',
'renio',
'renos',
'renta',
'rente',
'rento',
'reoca',
'reojo',
'repon',
'repos',
'repta',
'repte',
'repto',
'resal',
'reses',
'resma',
'resol',
'respe',
'resta',
'reste',
'resto',
'retad',
'retal',
'retan',
'retar',
'retas',
'retel',
'reten',
'retes',
'retin',
'retor',
'retos',
'retro',
'reuma',
'reuna',
'reune',
'reuni',
'reuno',
'revea',
'reved',
'reven',
'reveo',
'rever',
'reves',
'revio',
'reyad',
'reyan',
'reyar',
'reyas',
'reyen',
'reyes',
'rezad',
'rezan',
'rezar',
'rezas',
'rezno',
'rezon',
'rezos',
'riada',
'riais',
'riata',
'ribas',
'ricas',
'ricen',
'rices',
'ricia',
'ricio',
'ricos',
'riega',
'riego',
'riela',
'riele',
'rielo',
'riera',
'riere',
'riese',
'rifad',
'rifan',
'rifar',
'rifas',
'rifen',
'rifes',
'rifle',
'rigen',
'riges',
'rigil',
'rigio',
'rigor',
'rigua',
'rigue',
'rijan',
'rijas',
'rijos',
'rilad',
'rilan',
'rilar',
'rilas',
'rilen',
'riles',
'rimad',
'riman',
'rimar',
'rimas',
'rimel',
'rimen',
'rimes',
'rimus',
'rinda',
'rinde',
'rindo',
'ringa',
'ringo',
'rioja',
'ripia',
'ripie',
'ripio',
'risas',
'risca',
'risco',
'risos',
'rispa',
'rispe',
'rispo',
'ritma',
'ritme',
'ritmo',
'riton',
'ritos',
'rival',
'rizad',
'rizal',
'rizan',
'rizar',
'rizas',
'rizon',
'rizos',
'roais',
'roana',
'roano',
'robad',
'roban',
'robar',
'robas',
'robda',
'roben',
'robes',
'robin',
'robla',
'roble',
'roblo',
'robos',
'robot',
'robra',
'robre',
'rocas',
'rocea',
'rocee',
'rocen',
'roceo',
'roces',
'rocha',
'roche',
'rocho',
'rocia',
'rocie',
'rocin',
'rocio',
'rocos',
'rodad',
'rodal',
'rodao',
'rodar',
'rodas',
'rodea',
'rodee',
'rodeo',
'rodia',
'rodil',
'rodio',
'rodos',
'roeis',
'roela',
'roera',
'roere',
'roete',
'rogad',
'rogar',
'rogas',
'rogos',
'rogue',
'roian',
'roias',
'roida',
'roido',
'roiga',
'roigo',
'rojal',
'rojas',
'rojea',
'rojee',
'rojeo',
'rojez',
'rojos',
'rolad',
'rolan',
'rolar',
'rolas',
'rolda',
'rolde',
'roldo',
'rolen',
'roleo',
'roles',
'rolla',
'rolle',
'rollo',
'rolos',
'roman',
'romas',
'rombo',
'romea',
'romeo',
'romin',
'romis',
'romos',
'rompa',
'rompe',
'rompi',
'rompo',
'ronca',
'ronce',
'ronco',
'ronda',
'ronde',
'rondo',
'rones',
'ronza',
'ronzo',
'ropas',
'ropon',
'roque',
'rorad',
'roran',
'rorar',
'roras',
'roren',
'rores',
'rorro',
'rosal',
'rosan',
'rosar',
'rosas',
'rosca',
'rosco',
'rosea',
'rosee',
'rosen',
'roseo',
'roses',
'rosjo',
'roson',
'rosos',
'rosta',
'roste',
'rosti',
'rosto',
'rotad',
'rotal',
'rotan',
'rotar',
'rotas',
'roten',
'rotes',
'rotor',
'rotos',
'rouge',
'royan',
'royas',
'royos',
'rozad',
'rozan',
'rozar',
'rozas',
'rozna',
'rozne',
'rozno',
'rozon',
'rozos',
'ruaba',
'ruada',
'ruado',
'ruais',
'ruana',
'ruano',
'ruara',
'ruare',
'ruase',
'rubea',
'rubeo',
'rubia',
'rubin',
'rubio',
'rubis',
'rublo',
'rubor',
'rubra',
'rubro',
'rucad',
'rucan',
'rucar',
'rucas',
'rucha',
'ruche',
'rucho',
'rucia',
'rucio',
'rucos',
'rudas',
'rudos',
'rueca',
'rueda',
'ruede',
'ruedo',
'ruega',
'ruego',
'rueis',
'ruejo',
'rufas',
'rufon',
'rufos',
'rugad',
'rugan',
'rugar',
'rugas',
'rugby',
'rugen',
'ruges',
'rugia',
'rugid',
'rugio',
'rugir',
'rugis',
'rugue',
'ruido',
'ruina',
'ruine',
'ruino',
'rujan',
'rujas',
'rujia',
'rujie',
'rujio',
'rulad',
'rulan',
'rular',
'rulas',
'rulen',
'rules',
'rulos',
'rumba',
'rumbe',
'rumbo',
'rumia',
'rumie',
'rumio',
'rumis',
'rumor',
'rumos',
'runas',
'runes',
'runga',
'rungo',
'runos',
'rupia',
'ruque',
'rural',
'rurru',
'rusas',
'rusco',
'rusel',
'ruses',
'rusia',
'rusos',
'rusta',
'ruste',
'rusti',
'rusto',
'rutad',
'rutan',
'rutar',
'rutas',
'rutel',
'ruten',
'rutes',
'sabea',
'sabed',
'saben',
'sabeo',
'saber',
'sabes',
'sabia',
'sabio',
'sabir',
'sable',
'sabor',
'sabra',
'sabre',
'sacad',
'sacan',
'sacar',
'sacas',
'saces',
'sacha',
'sache',
'sacho',
'sacia',
'sacie',
'sacio',
'sacon',
'sacos',
'sacra',
'sacre',
'sacro',
'saeta',
'saete',
'saeti',
'saeto',
'safir',
'sagas',
'sagaz',
'sagus',
'saina',
'saine',
'saino',
'sajad',
'sajan',
'sajar',
'sajas',
'sajen',
'sajes',
'sajia',
'sajon',
'salad',
'salan',
'salar',
'salas',
'salaz',
'salce',
'salda',
'salde',
'saldo',
'salea',
'salee',
'salen',
'saleo',
'salep',
'sales',
'salga',
'salgo',
'salia',
'salid',
'salin',
'salio',
'salir',
'salis',
'salla',
'salle',
'sallo',
'salma',
'salme',
'salmo',
'salol',
'salon',
'salpa',
'salsa',
'salso',
'salta',
'salte',
'salto',
'salud',
'salva',
'salve',
'salvo',
'saman',
'samas',
'samba',
'sambo',
'samia',
'samio',
'sampa',
'sanad',
'sanan',
'sanar',
'sanas',
'sanco',
'sanea',
'sanee',
'sanen',
'saneo',
'sanes',
'sango',
'sanie',
'sanja',
'sanje',
'sanjo',
'sanos',
'sansa',
'sanso',
'santa',
'santo',
'sapas',
'sapea',
'sapee',
'sapeo',
'sapos',
'saque',
'saran',
'sarao',
'sarda',
'sarde',
'sardo',
'sarga',
'sargo',
'saria',
'saris',
'sarna',
'sarro',
'sarta',
'sarza',
'sarzo',
'sasal',
'satan',
'satas',
'saten',
'satin',
'satis',
'satos',
'sauce',
'sauco',
'saudi',
'sauna',
'savia',
'saxea',
'saxeo',
'saxos',
'sayal',
'sayas',
'sayon',
'sayos',
'sazon',
'seais',
'sebes',
'sebos',
'secad',
'secan',
'secar',
'secas',
'secon',
'secos',
'secta',
'secua',
'sedad',
'sedal',
'sedan',
'sedar',
'sedas',
'sedea',
'sedee',
'seden',
'sedeo',
'sedes',
'segad',
'segar',
'segas',
'segri',
'segue',
'segui',
'segun',
'segur',
'seibo',
'seico',
'seise',
'sejes',
'seles',
'sella',
'selle',
'sello',
'selva',
'semas',
'semen',
'semis',
'senas',
'senda',
'senes',
'senil',
'senos',
'senta',
'sente',
'senti',
'sento',
'seora',
'sepan',
'sepas',
'sepes',
'sepia',
'septo',
'seque',
'seran',
'seras',
'serba',
'serbo',
'seres',
'seria',
'serie',
'serio',
'serna',
'seron',
'serpa',
'serra',
'serre',
'serro',
'servi',
'servo',
'sesea',
'sesee',
'sesen',
'seseo',
'seses',
'sesga',
'sesgo',
'sesil',
'sesis',
'sesma',
'sesmo',
'sesos',
'setal',
'setas',
'setos',
'sexad',
'sexan',
'sexar',
'sexas',
'sexen',
'sexes',
'sexma',
'sexmo',
'sexos',
'sexta',
'sexto',
'short',
'shuar',
'sibil',
'siclo',
'sicus',
'sidas',
'sidra',
'siega',
'siego',
'siena',
'sieso',
'siete',
'sifon',
'sifue',
'sigan',
'sigas',
'sigla',
'siglo',
'sigma',
'signa',
'signe',
'signo',
'sigua',
'sigue',
'sijes',
'sijus',
'silba',
'silbe',
'silbo',
'siles',
'silex',
'silfo',
'silga',
'silgo',
'silla',
'silos',
'silva',
'simas',
'simia',
'simil',
'simio',
'simon',
'simpa',
'simun',
'singa',
'singo',
'sinos',
'sioux',
'sipes',
'sique',
'siras',
'sirga',
'sirgo',
'siria',
'sirin',
'sirio',
'sirle',
'siros',
'sirte',
'sirva',
'sirve',
'sirvo',
'sisad',
'sisal',
'sisan',
'sisar',
'sisas',
'sisca',
'sisea',
'sisee',
'sisen',
'siseo',
'sises',
'sismo',
'sison',
'sitas',
'sitia',
'sitie',
'sitio',
'sitos',
'situa',
'situe',
'situo',
'soasa',
'soase',
'soaso',
'sobad',
'soban',
'sobar',
'sobas',
'soben',
'sobeo',
'sobes',
'sobon',
'sobos',
'sobra',
'sobre',
'sobro',
'socas',
'socaz',
'soche',
'socia',
'socio',
'socol',
'sodas',
'sodio',
'soeza',
'sofas',
'sofis',
'sogas',
'sogun',
'sojas',
'solad',
'solar',
'solas',
'solaz',
'solda',
'solde',
'soldo',
'solea',
'solee',
'solen',
'soleo',
'soler',
'soles',
'solfa',
'solia',
'solio',
'solla',
'sollo',
'solos',
'solta',
'solte',
'solto',
'somas',
'somos',
'sonad',
'sonar',
'sonas',
'sonda',
'sonde',
'sondo',
'sones',
'sonio',
'sonsa',
'sonso',
'sonta',
'sonto',
'sopad',
'sopan',
'sopar',
'sopas',
'sopea',
'sopee',
'sopen',
'sopeo',
'sopes',
'sopie',
'sopla',
'sople',
'soplo',
'sopon',
'sopor',
'sorba',
'sorbe',
'sorbi',
'sorbo',
'sorce',
'sorda',
'sordo',
'sores',
'sorgo',
'sorna',
'sorne',
'sorno',
'soror',
'soros',
'sorra',
'sosal',
'sosar',
'sosas',
'sosia',
'sosos',
'sotad',
'sotan',
'sotar',
'sotas',
'soten',
'sotes',
'sotil',
'sotol',
'sotos',
'sovoz',
'soyas',
'sport',
'spray',
'stand',
'suaba',
'suabo',
'suata',
'suato',
'suave',
'suazi',
'suban',
'subas',
'suben',
'subeo',
'subes',
'subia',
'subid',
'subio',
'subir',
'subis',
'subte',
'suche',
'sucia',
'sucio',
'sucos',
'sucre',
'sucus',
'sudad',
'sudan',
'sudar',
'sudas',
'suden',
'sudes',
'sudor',
'sueca',
'sueco',
'suela',
'suele',
'suelo',
'suena',
'suene',
'sueno',
'suero',
'sueva',
'suevo',
'sufis',
'sufra',
'sufre',
'sufri',
'sufro',
'suido',
'suita',
'suite',
'suiza',
'suizo',
'sulas',
'sulco',
'sulla',
'sumad',
'suman',
'sumar',
'sumas',
'sumen',
'sumes',
'sumia',
'sumid',
'sumio',
'sumir',
'sumis',
'sumos',
'sumus',
'sunca',
'sunco',
'super',
'supla',
'suple',
'supli',
'suplo',
'supon',
'supra',
'sural',
'suras',
'surca',
'surco',
'surda',
'surde',
'surdi',
'surdo',
'sures',
'surfs',
'surge',
'surgi',
'suris',
'surja',
'surjo',
'surta',
'surte',
'surti',
'surto',
'suses',
'susto',
'sutas',
'sutes',
'sutil',
'suyas',
'suyos',
'suzon',
'tabal',
'tabas',
'tabea',
'tabes',
'tabis',
'tabla',
'table',
'tablo',
'tabon',
'tabor',
'tabos',
'tabus',
'tacar',
'tacas',
'tacen',
'taces',
'tacet',
'tacha',
'tache',
'tacho',
'tacon',
'tacos',
'tacto',
'tafia',
'tafon',
'tafos',
'tafur',
'tagua',
'tahas',
'tahur',
'taifa',
'taiga',
'taima',
'taime',
'taimo',
'taina',
'taino',
'taipa',
'taira',
'taire',
'taita',
'tajad',
'tajan',
'tajar',
'tajas',
'tajea',
'tajee',
'tajen',
'tajeo',
'tajes',
'tajin',
'tajon',
'tajos',
'tajus',
'talad',
'talan',
'talar',
'talas',
'talco',
'talea',
'taled',
'talen',
'tales',
'talgo',
'talin',
'talio',
'talla',
'talle',
'tallo',
'talma',
'talon',
'talos',
'talpa',
'talud',
'tamal',
'tamba',
'tambo',
'tamil',
'tamiz',
'tamos',
'tamul',
'tanas',
'tanca',
'tanco',
'tanda',
'tanes',
'tanga',
'tange',
'tangi',
'tango',
'tanja',
'tanjo',
'tanor',
'tanos',
'tanta',
'tanto',
'tanza',
'tapad',
'tapan',
'tapar',
'tapas',
'tapea',
'tapee',
'tapen',
'tapeo',
'tapes',
'tapia',
'tapie',
'tapin',
'tapio',
'tapir',
'tapis',
'tapiz',
'tapon',
'taque',
'tarad',
'taran',
'tarar',
'taras',
'taray',
'tarca',
'tarco',
'tarda',
'tarde',
'tardo',
'tarea',
'taren',
'tares',
'tarin',
'tarja',
'tarje',
'tarjo',
'tarma',
'tarot',
'tarra',
'tarro',
'tarso',
'tarta',
'tasad',
'tasan',
'tasar',
'tasas',
'tasca',
'tasco',
'tasen',
'tases',
'tasia',
'tasio',
'tasis',
'tasto',
'tatas',
'tatay',
'tatos',
'tatua',
'tatue',
'tatuo',
'tatus',
'tauca',
'tauco',
'taula',
'tauro',
'taxis',
'taxon',
'tayos',
'tayul',
'tazad',
'tazan',
'tazar',
'tazas',
'tazon',
'teame',
'tebea',
'tebeo',
'tecas',
'teces',
'techa',
'teche',
'techo',
'tecla',
'tecle',
'teclo',
'tecol',
'tedas',
'tedio',
'tefes',
'tegea',
'tegeo',
'tegua',
'tegue',
'tehul',
'teina',
'tejad',
'tejan',
'tejar',
'tejas',
'tejed',
'tejen',
'tejer',
'tejes',
'tejia',
'tejio',
'tejon',
'tejos',
'telar',
'telas',
'teles',
'telex',
'telon',
'temad',
'teman',
'temar',
'temas',
'temed',
'temen',
'temer',
'temes',
'temia',
'temio',
'temor',
'tempo',
'temus',
'tenas',
'tenaz',
'tenca',
'tende',
'tendi',
'tened',
'tener',
'tenes',
'tenga',
'tengo',
'tenia',
'tenio',
'tenis',
'tenor',
'tensa',
'tense',
'tenso',
'tenta',
'tente',
'tento',
'tenue',
'teosa',
'teoso',
'tepes',
'tepus',
'tepuy',
'teque',
'terca',
'terco',
'terma',
'termo',
'terna',
'terne',
'terno',
'teros',
'tersa',
'terse',
'terso',
'tesad',
'tesan',
'tesar',
'tesas',
'tesen',
'teses',
'tesis',
'tesla',
'teson',
'tesos',
'testa',
'teste',
'testo',
'tetad',
'tetan',
'tetar',
'tetas',
'teten',
'tetes',
'teton',
'tetra',
'tetro',
'texes',
'texto',
'teyas',
'teyos',
'teyus',
'tiaca',
'tiara',
'tibar',
'tibes',
'tibia',
'tibie',
'tibio',
'tibor',
'ticas',
'ticos',
'tiene',
'tiesa',
'tieso',
'tifas',
'tifon',
'tifos',
'tifus',
'tigra',
'tigre',
'tigua',
'tigue',
'tijas',
'tijos',
'tijul',
'tilas',
'tilda',
'tilde',
'tildo',
'tiles',
'tilia',
'tilin',
'tilla',
'tille',
'tillo',
'tilma',
'tilos',
'timad',
'timan',
'timar',
'timas',
'timba',
'timbo',
'timen',
'times',
'timol',
'timon',
'timos',
'timpa',
'tinas',
'tinca',
'tinco',
'tinea',
'tineo',
'tinge',
'tinos',
'tinta',
'tinte',
'tinto',
'tipas',
'tipis',
'tiple',
'tipoi',
'tipos',
'tipoy',
'tique',
'tiqui',
'tirad',
'tiran',
'tirar',
'tiras',
'tiren',
'tires',
'tiria',
'tirio',
'tiron',
'tiros',
'tirro',
'tirso',
'tirte',
'tisis',
'tiste',
'tisus',
'titad',
'titan',
'titar',
'titas',
'titea',
'titee',
'titen',
'titeo',
'tites',
'titil',
'titis',
'titos',
'tizas',
'tizna',
'tizne',
'tizno',
'tizon',
'tizos',
'tlaco',
'toaba',
'toada',
'toado',
'toais',
'toara',
'toare',
'toase',
'tobar',
'tobas',
'tocad',
'tocan',
'tocar',
'tocas',
'tocen',
'toces',
'tocha',
'toche',
'tocho',
'tocia',
'tocio',
'tocon',
'tocos',
'tocte',
'todas',
'todia',
'todos',
'toeis',
'toesa',
'tofos',
'togan',
'togar',
'togas',
'togue',
'tojal',
'tojos',
'tolas',
'tolda',
'tolde',
'toldo',
'toles',
'tolla',
'tollo',
'tolmo',
'tolon',
'tolva',
'tomad',
'toman',
'tomar',
'tomas',
'tomen',
'tomes',
'tomin',
'tomon',
'tomos',
'tonad',
'tonal',
'tonan',
'tonar',
'tonas',
'tonca',
'tondo',
'tonel',
'tonen',
'toner',
'tones',
'tonga',
'tongo',
'tonos',
'tonta',
'tonto',
'topad',
'topan',
'topar',
'topas',
'topea',
'topee',
'topen',
'topeo',
'topes',
'topia',
'topil',
'topon',
'topos',
'toque',
'toqui',
'toral',
'toras',
'torax',
'torca',
'torce',
'torci',
'torco',
'torda',
'tordo',
'torea',
'toree',
'toreo',
'tores',
'torga',
'torgo',
'toril',
'torio',
'tormo',
'torna',
'torne',
'torno',
'toron',
'toros',
'torpe',
'torra',
'torre',
'torro',
'torso',
'torta',
'torva',
'torvo',
'tosan',
'tosas',
'tosca',
'tosco',
'tosed',
'tosen',
'toser',
'toses',
'tosia',
'tosio',
'tosta',
'toste',
'tosto',
'total',
'totem',
'totes',
'totis',
'totol',
'tovas',
'tozad',
'tozal',
'tozan',
'tozar',
'tozas',
'tozos',
'traba',
'trabe',
'trabo',
'traca',
'trace',
'traed',
'traen',
'traer',
'traes',
'trafa',
'traga',
'trago',
'traia',
'traje',
'trajo',
'trama',
'trame',
'tramo',
'trapa',
'trape',
'trapo',
'traro',
'trata',
'trate',
'trato',
'travo',
'traza',
'trazo',
'trebo',
'trece',
'trefe',
'treja',
'trema',
'treme',
'tremi',
'tremo',
'trena',
'treno',
'treos',
'trepa',
'trepe',
'trepo',
'treta',
'triad',
'trial',
'trian',
'triar',
'trias',
'tribu',
'trice',
'trien',
'tries',
'triga',
'trigo',
'trile',
'trina',
'trine',
'trino',
'trios',
'tripa',
'tripe',
'trisa',
'trise',
'triso',
'triza',
'trizo',
'troca',
'troce',
'troco',
'troja',
'troje',
'trola',
'trole',
'trona',
'trone',
'trono',
'tropa',
'tropo',
'trota',
'trote',
'troto',
'trova',
'trove',
'trovo',
'troza',
'trozo',
'truca',
'truco',
'trues',
'trufa',
'trufe',
'trufo',
'truja',
'trusa',
'trust',
'tuani',
'tubas',
'tubos',
'tucan',
'tucas',
'tucia',
'tucos',
'tucun',
'tudas',
'tudel',
'tueca',
'tueco',
'tuera',
'tuero',
'tufea',
'tufee',
'tufeo',
'tufos',
'tuina',
'tular',
'tules',
'tulio',
'tulla',
'tulle',
'tulli',
'tullo',
'tulpa',
'tumba',
'tumbe',
'tumbo',
'tumor',
'tumos',
'tunad',
'tunal',
'tunan',
'tunar',
'tunas',
'tunca',
'tunco',
'tunda',
'tunde',
'tundi',
'tundo',
'tunea',
'tunee',
'tunel',
'tunen',
'tuneo',
'tunes',
'tunjo',
'tunos',
'tunta',
'tupan',
'tupas',
'tupen',
'tupes',
'tupia',
'tupid',
'tupin',
'tupio',
'tupir',
'tupis',
'tupos',
'turar',
'turba',
'turbe',
'turbo',
'turca',
'turco',
'turma',
'turna',
'turne',
'turno',
'turon',
'turra',
'turre',
'turro',
'tusad',
'tusan',
'tusar',
'tusas',
'tusca',
'tusco',
'tusen',
'tuses',
'tuson',
'tusos',
'tutas',
'tutea',
'tutee',
'tuteo',
'tutes',
'tutia',
'tutor',
'tutos',
'tutus',
'tuyas',
'tuyos',
'tuzas',
'ubica',
'ubico',
'ubies',
'ubios',
'ubres',
'ucase',
'uchus',
'uebos',
'ufana',
'ufane',
'ufano',
'ugres',
'ujier',
'ujule',
'ulaga',
'ulala',
'ulano',
'ulema',
'ulpos',
'ultra',
'uluas',
'ulula',
'ulule',
'ululo',
'umbra',
'umbro',
'umero',
'unais',
'uncen',
'unces',
'uncia',
'uncid',
'uncio',
'uncir',
'uncis',
'ungen',
'unges',
'ungia',
'ungid',
'ungio',
'ungir',
'ungis',
'unian',
'unias',
'unica',
'unico',
'unida',
'unido',
'union',
'unira',
'unire',
'unjan',
'unjas',
'untad',
'untan',
'untar',
'untas',
'unten',
'untes',
'untos',
'unzan',
'unzas',
'upaba',
'upada',
'upado',
'upais',
'upara',
'upare',
'upase',
'upeis',
'upupa',
'uraos',
'urape',
'urato',
'urbes',
'urcas',
'urces',
'urdan',
'urdas',
'urden',
'urdes',
'urdia',
'urdid',
'urdio',
'urdir',
'urdis',
'urdus',
'ureas',
'urgen',
'urges',
'urgia',
'urgid',
'urgio',
'urgir',
'urgis',
'urica',
'urico',
'urjan',
'urjas',
'urnas',
'urtas',
'urubu',
'urucu',
'uruga',
'usaba',
'usada',
'usado',
'usais',
'usaje',
'usara',
'usare',
'usase',
'useis',
'usgos',
'usias',
'usier',
'usina',
'usted',
'usual',
'usura',
'usure',
'usuro',
'utero',
'uvada',
'uvate',
'uveas',
'uvera',
'uvero',
'uviar',
'uvula',
'vacad',
'vacan',
'vacar',
'vacas',
'vacia',
'vacie',
'vacio',
'vacos',
'vacua',
'vacuo',
'vadea',
'vadee',
'vadeo',
'vades',
'vados',
'vafea',
'vafee',
'vafeo',
'vagad',
'vagan',
'vagar',
'vagas',
'vagon',
'vagos',
'vague',
'vahad',
'vahan',
'vahar',
'vahas',
'vahea',
'vahee',
'vahen',
'vaheo',
'vahes',
'vahos',
'vaida',
'vaina',
'vajea',
'vajee',
'vajeo',
'valar',
'valed',
'valen',
'valer',
'vales',
'valet',
'valga',
'valgo',
'valia',
'valio',
'valis',
'valla',
'valle',
'vallo',
'valon',
'valor',
'valsa',
'valse',
'valso',
'valua',
'value',
'valuo',
'valva',
'vamos',
'vanas',
'vanea',
'vanee',
'vaneo',
'vanos',
'vapor',
'vaque',
'varad',
'varal',
'varan',
'varar',
'varas',
'varea',
'varee',
'varen',
'vareo',
'vares',
'varga',
'varia',
'varie',
'vario',
'varis',
'variz',
'varon',
'vasar',
'vasas',
'vasca',
'vasco',
'vasos',
'vasta',
'vasto',
'vater',
'vates',
'vatio',
'vayan',
'vayas',
'veais',
'vecen',
'veces',
'vedad',
'vedan',
'vedar',
'vedas',
'veden',
'vedes',
'vegas',
'veian',
'veias',
'vejad',
'vejan',
'vejar',
'vejas',
'vejen',
'vejes',
'vejez',
'velad',
'velan',
'velar',
'velas',
'velay',
'velen',
'veles',
'velis',
'veliz',
'vello',
'velon',
'velos',
'veloz',
'vemos',
'venal',
'venas',
'vence',
'venci',
'venda',
'vende',
'vendi',
'vendo',
'venga',
'vengo',
'venia',
'venid',
'venir',
'venis',
'venta',
'vente',
'vento',
'venus',
'venza',
'venzo',
'veran',
'veras',
'veraz',
'verba',
'verbo',
'verde',
'veres',
'verga',
'verge',
'veria',
'veril',
'verja',
'verme',
'vermu',
'veros',
'versa',
'verse',
'verso',
'verte',
'verti',
'veste',
'vesti',
'vetad',
'vetan',
'vetar',
'vetas',
'vetea',
'vetee',
'veten',
'veteo',
'vetes',
'veton',
'vetos',
'vezad',
'vezan',
'vezar',
'vezas',
'viada',
'viaja',
'viaje',
'viajo',
'vibra',
'vibre',
'vibro',
'vicha',
'viche',
'vicho',
'vichy',
'vicia',
'vicie',
'vicio',
'vicos',
'victo',
'vidas',
'video',
'vides',
'vidon',
'vidro',
'vieja',
'viejo',
'viene',
'viera',
'viere',
'viesa',
'viese',
'vigas',
'vigia',
'vigie',
'vigio',
'vigor',
'viles',
'villa',
'vilos',
'vimos',
'vinal',
'vinar',
'vinca',
'vinco',
'vinos',
'vinta',
'viola',
'viole',
'violo',
'virad',
'viral',
'viran',
'virar',
'viras',
'viren',
'vireo',
'vires',
'virgo',
'viril',
'virio',
'virol',
'viron',
'virus',
'visad',
'visan',
'visar',
'visas',
'visco',
'visea',
'visee',
'visen',
'viseo',
'vises',
'visir',
'vison',
'visor',
'visos',
'vista',
'viste',
'visto',
'vitad',
'vital',
'vitan',
'vitar',
'vitas',
'viten',
'vites',
'vitor',
'vitos',
'vitre',
'viuda',
'viudo',
'vivac',
'vivad',
'vivan',
'vivar',
'vivas',
'vivaz',
'viven',
'vives',
'vivez',
'vivia',
'vivid',
'vivio',
'vivir',
'vivis',
'vivon',
'vivos',
'voace',
'vocal',
'vocea',
'vocee',
'voceo',
'voces',
'vodca',
'vodus',
'voila',
'volad',
'volar',
'volas',
'volca',
'volco',
'volea',
'volee',
'voleo',
'volon',
'volts',
'volve',
'volvi',
'volvo',
'vomer',
'voraz',
'vosea',
'vosee',
'voseo',
'votad',
'votan',
'votar',
'votas',
'voten',
'votes',
'votos',
'votri',
'vozna',
'vozne',
'vozno',
'vudus',
'vuela',
'vuele',
'vuelo',
'vuesa',
'vueso',
'vulgo',
'vulto',
'vulva',
'vusco',
'xecas',
'xenon',
'xinca',
'xiote',
'xolas',
'xolos',
'yabas',
'yacal',
'yacas',
'yaced',
'yacen',
'yacer',
'yaces',
'yacia',
'yacio',
'yacon',
'yagan',
'yagas',
'yagua',
'yaiti',
'yales',
'yamao',
'yambo',
'yampa',
'yanas',
'yanta',
'yante',
'yanto',
'yapad',
'yapan',
'yapar',
'yapas',
'yapen',
'yapes',
'yapus',
'yaque',
'yaqui',
'yarda',
'yares',
'yarey',
'yaros',
'yatai',
'yatay',
'yates',
'yayas',
'yayos',
'yazca',
'yazco',
'yazga',
'yazgo',
'yebos',
'yecos',
'yedgo',
'yedra',
'yegua',
'yelgo',
'yelmo',
'yemas',
'yendo',
'yenes',
'yente',
'yeral',
'yerba',
'yerga',
'yergo',
'yerma',
'yerme',
'yermo',
'yerna',
'yerno',
'yeros',
'yerra',
'yerre',
'yerro',
'yersi',
'yerta',
'yerto',
'yervo',
'yesal',
'yesar',
'yesca',
'yeson',
'yesos',
'yetis',
'yeyes',
'yeyos',
'yezgo',
'yinas',
'yines',
'yiras',
'yiros',
'yodad',
'yodan',
'yodar',
'yodas',
'yoden',
'yodes',
'yodos',
'yogar',
'yogas',
'yogos',
'yogui',
'yogur',
'yolas',
'yoqui',
'yoris',
'yoses',
'yoyos',
'yucal',
'yucas',
'yucpa',
'yudos',
'yugos',
'yumba',
'yumbo',
'yunga',
'yunta',
'yunto',
'yuras',
'yures',
'yutas',
'yutes',
'yuyal',
'yuyos',
'zabra',
'zabro',
'zacas',
'zacea',
'zacee',
'zaceo',
'zades',
'zafad',
'zafan',
'zafar',
'zafas',
'zafen',
'zafes',
'zafia',
'zafio',
'zafir',
'zafon',
'zafos',
'zafra',
'zafre',
'zagal',
'zagas',
'zagua',
'zahen',
'zahon',
'zaida',
'zaina',
'zaino',
'zajon',
'zalas',
'zalba',
'zalbo',
'zalea',
'zalee',
'zaleo',
'zalla',
'zalle',
'zallo',
'zamba',
'zambo',
'zampa',
'zampe',
'zampo',
'zanas',
'zanca',
'zanco',
'zanga',
'zanja',
'zanje',
'zanjo',
'zapad',
'zapan',
'zapar',
'zapas',
'zapea',
'zapee',
'zapen',
'zapeo',
'zapes',
'zaque',
'zarbo',
'zarca',
'zarco',
'zares',
'zarja',
'zarpa',
'zarpe',
'zarpo',
'zarza',
'zarzo',
'zatas',
'zayas',
'zazas',
'zazos',
'zebra',
'zedas',
'zegri',
'zeina',
'zejel',
'zenda',
'zendo',
'zenes',
'zenit',
'zetas',
'zincs',
'zocad',
'zocan',
'zocar',
'zocas',
'zoclo',
'zocos',
'zofra',
'zoilo',
'zoizo',
'zolle',
'zomas',
'zombi',
'zomos',
'zompa',
'zompo',
'zonal',
'zonas',
'zonda',
'zonta',
'zonto',
'zonza',
'zonzo',
'zopas',
'zopes',
'zopos',
'zoque',
'zorra',
'zorro',
'zotal',
'zotes',
'zotol',
'zuavo',
'zubia',
'zudas',
'zueca',
'zueco',
'zuela',
'zuiza',
'zulla',
'zulle',
'zullo',
'zulus',
'zumas',
'zumba',
'zumbe',
'zumbo',
'zumos',
'zunas',
'zunza',
'zupia',
'zuras',
'zurba',
'zurce',
'zurci',
'zurda',
'zurde',
'zurdi',
'zurdo',
'zurea',
'zuree',
'zureo',
'zuros',
'zurra',
'zurre',
'zurri',
'zurro',
'zurza',
'zurzo',
'zuzar',
'zuzon',
]
