import React, { useState, useEffect } from 'react';
import { solution } from '../../lib/words';
import axios, { AxiosResponse } from 'axios';

export const getDefinition = async (wordOfTheDay: string) => {
  const apiKey = 'werdle';
  const apiUrl = 'https://api.xavier.cc/dictionary/spanish/definition?word=' + wordOfTheDay;
  
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': ` ${apiKey}`,
  };

  try {
    const response: AxiosResponse = await axios.get(apiUrl, { headers });

    if (!response.data || !response.data.definition) {
      console.error('Definition not found in the response');
      return "Definición no encontrada";
    }

    const definition = response.data.definition;
      console.log('Word:', wordOfTheDay);
      console.log('Data received:', definition);
    return definition;
  } catch (error) {
      console.error('Error fetching data:', error);
    return "Definición no encontrada";
  }
}

export const Definition = () => {
  const [definition, setDefinition] = useState<string | null>(null);

  useEffect(() => {
    const fetchDefinition = async () => {
      try {
        const result = await getDefinition(solution);
        setDefinition(result);
      } catch (error) {
        console.error('Error in useEffect:', error);
        setDefinition("Definición no encontrada");
      }
    };

    fetchDefinition();
  }, []); // Empty dependency array to run the effect once on mount

  return (
    <div className="mt-5">
      <hr className="mt-4 -mb-4 border-gray-500" />
      <div className="text-xs items-center items-stretch justify-center text-left dark:text-white sm:mt-6">
        <strong>{solution}</strong> <i>(definición de palabra del día)</i><br/>
        {definition}
      </div>     
      <hr className="mt-4 -mb-4 border-gray-500" />
    </div>
  )
}